.sections-section {
  height: 75rem;
}

.sections-bg-img {
  /* position: absolute; */
  width: 89.75rem;
  height: 22.625rem;
  min-height: 20rem;
  /* margin-top: -10rem; */
}

.sections-container {
  margin: -12rem 5rem 10rem 5rem;
  width: 75rem;
  height: 70rem;
  max-height: 60rem;
  border-radius: 1.25rem;
  background: #fff;
  padding: 3rem 6rem;
  box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.12);
  z-index: 10;
  position: absolute;
}

.sections-heading {
  color: #6d6d6d;
  font-family: Roboto;
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.43919rem; /* 127.928% */
  letter-spacing: 0.03125rem;
}

.sections-progress {
  color: #6d6d6d;
  font-family: Roboto;
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.43919rem; /* 127.928% */
  letter-spacing: 0.03125rem;
  margin: 2rem 0;
}

.sections-name {
  width: 72.4375rem;
  flex-shrink: 0;
  color: #000;
  font-family: Roboto;
  font-size: 2.125rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1.43919rem; /* 67.727% */
  margin: 1rem 0;
  border-bottom: 0.0625rem solid #e0e0e0;
}

.sections-desc {
  width: 68rem;
  color: #000;
  font-family: Roboto;
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 1.6875rem */
  letter-spacing: 0.0225rem;
  margin: 1rem 0 3rem 0;
}

.sections-video-img {
  width: 75rem;
  height: 27.375rem;
  justify-content: center;
  align-items: center;
  margin: 1rem -6rem;
}

.sections-footer {
  display: inline-flex;
  gap: 58rem;
  margin-top: 1rem;
  width: 75rem;
  margin-left: 1rem;
}

.sections-transcript {
  color: #000;
  font-family: Roboto;
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 1.6875rem */
  letter-spacing: 0.0225rem;
  display: flex;
  gap: 0.5rem;
  margin: -2rem 0 -1rem -4rem;
}

.transcript-img {
  width: 1.57rem;
  height: 1.25rem;
  margin-top: 0.2rem;
}

.transcript-text {
  display: flex;
  flex-direction: column;
  width: 8.5625rem;
}

.transcript-line {
  width: 8.25rem;
  height: 0.0625rem;
  background: #000;
}

.transcript-bookmark {
  width: 1.5rem;
  height: 1.5rem;
  margin-top: -4rem;
}

.sections-btn {
  margin-top: 1rem;
  margin-left: 4rem;
  display: flex;
  height: 2rem;
  padding: 0.625rem 0.25rem;
  justify-content: right;
  align-items: right;
  gap: 0.625rem;
}

@media (max-width: 425px) {
  .sections-sidebar {
    display: none;
  }
  .sections-section {
    background-image: url("../assets/sections/Card_FC.png");
    height: 65rem;
  }
  .sections-bg-img {
    display: none;
    /* margin-top: 2rem;
    width: 100%;
    height: 10rem; */
  }
  .sections-container {
    margin: 10rem 0.5rem 2rem 0.5rem;
    width: 95%;
    height: max-content;
    padding: 2rem 1rem;
    box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.12);
    z-index: 10;
    position: absolute;
  }
  .sections-heading {
    display: block;
    font-size: 1rem;
    line-height: 1rem;
    margin-top: -5rem;
  }
  .sections-name {
    font-size: 1rem;
    margin: 0;
    border-bottom: 0.0625rem solid #e0e0e0;
  }
  .sections-desc {
    width: 100%;
  }
  .sections-video-img {
    display: none;
  }
  .sections-footer {
    margin-left: 4rem;
    gap: 8rem;
  }
}
