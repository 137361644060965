.WorksheetCard {
  .MuiCardContent-root {
    display: flex;
    flex-direction: column;
    gap: 5px;
    align-items: center;
    justify-content: center;
    margin-top: auto;
    margin-bottom: auto;

    .css-anmq6p-MuiTypography-root {
      margin-bottom: 0 !important;
    }

    .MuiTypography-b {
      color: #000;
      text-align: center;
      font-size: 18px;
      font-family: Inter;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }

    .MuiTypography-span {
      color: #656565;
      text-align: center;
      font-size: 14px;
      font-family: Inter;
      font-style: normal;
      font-weight: 400;
      line-height: 145.023%;
      letter-spacing: 0.21px;
    }

    .MuiAvatarGroup-root {
        margin-top: 20px;
    }
  }
}
