.details-section {
  max-width: 100%;
  max-height: 95vh;
  background: linear-gradient(
    100.36deg,
    #f5f1ff 4.42%,
    rgba(245, 241, 255, 0) 100.68%
  );

  padding: 10rem 15rem;
  display: flex;
}

.details-main {
  width: 80%;
  max-height: 70%;
  margin: -5rem 0;
  display: flex;
  flex-direction: column;
}

.details-heading {
  width: 130%;
  font-family: roboto;
  /* margin-top: -2rem; */
  font-weight: 600;
  font-size: 3rem;
  line-height: 5rem;
  text-align: center;
  /* margin-left: -3rem; */
}

.details-blue {
  color: rgba(84, 28, 245, 1);
  font-weight: 700;
}

.details-list {
  width: 32rem;
  height: 21rem;
  margin: 2rem;
  /* display: flex;
  flex-direction: row;
  gap: 1rem; */
}

.details-list-items {
  list-style: none;
  list-style-image: url("../../components/courseNew/assets/green_check_mark.png");
  width: 100%;
  font-family: roboto;
  font-weight: 600;
  font-size: 1.2rem;
  line-height: 2rem;
  letter-spacing: 2%;
  margin-bottom: 1rem;
  margin-left: 20%;
}

.details-btn {
  width: 18rem;
  height: 3.25rem;
  margin-top: 1rem;
  margin-left: 15rem;
  gap: 1.5px;
  border-radius: 54px;
  padding: 0.8rem 3.1rem;
  background-color: #fd6f1f;
  font-family: helvetica;
  font-weight: 700;
  font-size: 1.1rem;
  line-height: 1.5rem;
  color: white;
  text-align: center;
}

.details-images-left {
  display: flex;
  flex-direction: column;
  gap: -4rem;
  width: 80%;
  /* margin-top: 1rem; */
  animation: img-animation 3s ease forwards;
}

.details-images-right {
  display: flex;
  flex-direction: column;
  width: 80%;
  gap: -4rem;
  /* margin-top: 1rem; */
  margin-left: 14rem;
  animation: float 3s ease forwards;
}

@keyframes float {
  0% {
    transform: translateX(50%);
  }
  100% {
    transform: translateX(-20%);
  }
}

.img1,
.img4 {
  margin-left: -4rem;
  border-radius: 100%;
  border: 2px white;
  box-shadow: 0px 0px 40px 0px #e7ffde99;
}

.img2,
.img3 {
  margin-left: 2rem;
  border-radius: 100%;
  border: 4px white;
  width: fit-content;
  box-shadow: 0px 0px 40px 0px #e7ffde99;
}

@keyframes img-animation {
  0% {
    transform: translateX(-50%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

@media (max-width: 425px) {
  .details-section {
    padding: 11rem 4rem 8rem 4rem;
    min-width: 100%;
    max-height: 50rem;
    /* margin-top: 8rem; */
  }
  .details-heading {
    width: 23rem;
    height: 6.75rem;
    font-size: 2rem;
    line-height: 2.5rem;
    text-align: left;
    padding: 0;
    margin-left: -3rem;
  }
  .details-list {
    width: 21rem;
    height: 20rem;
    margin: 1rem 4rem;
  }
  .details-list-items {
    width: 105%;
    font-family: roboto;
    font-weight: 600;
    font-size: 1rem;
    line-height: 1.5rem;
    margin: 0.75rem -7rem;
    letter-spacing: 2%;
  }
  .details-images-left,
  .details-images-right {
    display: none;
  }
  .details-btn {
    margin: 3rem 0 0 0;
  }
}
