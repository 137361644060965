.Prescription--Dialog{
    .Content--Header {
        border-radius: 10px;
        border: 1px solid #f5f5f5;
        background: #f5f5f5;
        padding: 15px;
        img {
          width: 45px;
          height: 45px;
        }
        h6 {
          color: #000;
          font-family: Inter;
          font-size: 16px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
        }
        span {
          color: #000;
          font-family: Inter;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          letter-spacing: 0.21px;
        }
      }
}