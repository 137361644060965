.practice {
  display: flex;
  overflow-x: hidden;
}

.practice-section {
  height: 120rem;
}

.practice-container {
  z-index: 10;
  position: absolute;
  margin: -14rem 0 5rem 2rem;
  width: 82.375rem;
  height: max-content;
  border-radius: 1.25rem;
  padding: 5rem;
  background: #fff;
  box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.12);
}

.practice-section-desc {
  width: 72.125rem;
  color: #252625;
  font-family: Roboto;
  font-size: 1.2rem;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 2.0625rem */
  margin: 1.5rem 0;
}

.lists {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.practice-try-list {
  width: 72.375rem;
  height: max-content;
  border-radius: 0.5rem;
  background: #f7f4ff;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.practice-list,
.done-list {
  padding: 2rem;
  width: 72.375rem;
  height: max-content;
  border-radius: 0.5rem;
  border: 1px solid #d2c1ff;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.list-title {
  color: #000;
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
}

.try-list-title {
  font-size: 1.7rem;
  line-height: 2.87838rem; /* 153.514% */
}

.practice-list-title {
  font-size: 1.6rem;
  line-height: 2.30269rem;
}

.list-items {
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
  cursor: pointer;
}

.try-item-desc {
  width: 32.875rem;
  color: #252625;
  font-family: Roboto;
  font-size: 1.2rem;
  font-style: normal;
  font-weight: 400;
  line-height: 2.40681rem; /* 175.043% */
}

.practice-item-desc {
  color: #252625;
  font-family: Roboto;
  font-size: 1.2rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.9255rem; /* 137.534% */
}

.plus-icon {
  margin-top: 0.5rem;
}

.tick-icon,
.plus-icon {
  width: 1.75rem;
  height: 1.75rem;
  /* margin-top: -0.2rem; */
}

.tick-icon {
  margin-top: 0.3rem;
  color: #d6c8ff;
}

.edit-dialog-icon {
  width: 0.4rem;
  height: 1.75rem;
  cursor: pointer;
}

.edit-popup {
  padding: 0.5rem;
  display: flex;
  width: 1.5rem;
  height: 2.5rem;
  flex-direction: column;
  align-items: flex-start;
  gap: 1rem;
  flex-shrink: 0;
  border-radius: 0.5rem;
  background-color: #f7f4ff;
  border: none;
}

.edit-popup:hover,
.edit-popup:active {
  background-color: #f7f4ff !important;
  border: none !important;
}

.edit-icon {
  color: #885fff;
}

.delete__icon {
  width: 1.3rem;
  height: 1.3rem;
}

.edit,
.delete {
  display: flex;
  gap: 0.5rem;
  cursor: pointer;
}

.try-list-item {
  display: flex;
  gap: 1rem;
}

.practice-list-item {
  display: flex;
  gap: 1rem;
}

.practice-btn {
  display: flex;
  width: 9.5rem;
  height: 3.15rem;
  padding: 0.71588rem 0.55806rem 0.71594rem 0.85913rem;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 0.75rem;
  border-radius: 0.57275rem;
  border: 1.145px solid #5e5c61;
  background: #fff;
  cursor: pointer;
}

.add-step-textarea {
  width: 20rem;
  display: flex;
  padding: 0.5625rem 1.4375rem 0.6875rem 0.8125rem;
  align-items: flex-start;
  gap: 1rem;
  border-radius: 0.8rem;
  border: 1.6px solid #541cf5;
  background: #fff;
}

.add-step-text {
  border: none;
  width: 19rem;
}

.add-step-save-text {
  cursor: pointer;
  color: #885fff;
  font-family: Roboto;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 500;
  line-height: 2.30269rem; /* 184.216% */
}

.plus-icon-btn {
  width: 1.718rem;
  height: 1.718rem;
  color: #885fff;
}

.btn-text {
  color: #885fff;
  font-family: Roboto;
  font-size: 1.28863rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1.6485rem; /* 127.928% */
}

.done-list-item {
  display: flex;
  gap: 1rem;
}

.daily-practice-btn {
  display: flex;
  /* justify-content: space-between; */
  gap: 50rem;
  margin-top: 2.5rem;
  cursor: pointer;
}

@media (max-width: 425px) {
  .practice-section {
    height: 70rem;
    margin-left: -1rem;
  }
  .daily-practice-sidebar {
    display: none;
  }
  .practice-container {
    /* z-index: 10;
    position: absolute;*/
    width: 100%;
    margin-top: 2rem;
    height: max-content;
    padding: 0;
    box-shadow: none;
  }
  .practice-section-desc {
    display: none;
  }
  .daily-practice-bg-img {
    display: none;
  }
  .sections-heading {
    display: none;
  }
  .sections-name {
    width: 90%;
    font-size: 1.25rem;
    line-height: 1.43919rem; /* 115.135% */
    letter-spacing: 0.03125rem;
    gap: 5rem;
  }
  .practice-try-list {
    width: 22rem;
    padding: 1rem;
    max-height: max-content;
    /* gap: 0.75rem; */
  }
  .try-list-title,
  .practice-list-title {
    font-size: 1.125rem;
    line-height: 1.44rem; /* 153.514% */
  }
  .try-item-desc {
    width: 100%;
    font-size: 1rem;
    line-height: 1.2rem; /* 153.514% */
  }
  .list-items {
    gap: 0.55rem;
  }
  .plus-icon {
    width: 0.875rem;
    height: 0.875rem;
    margin-top: -0.05rem;
  }
  .practice-list,
  .done-list {
    padding: 1rem;
    width: 90%;
    height: max-content;
    gap: 1rem;
  }
  .practice-item-desc {
    font-size: 1rem;
    line-height: 1.2rem; /* 137.534% */
  }
  .tick-icon {
    width: 0.875rem;
    height: 0.875rem;
  }
  .daily-practice-btn {
    gap: 2.5rem;
  }
}
