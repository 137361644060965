:root {
  --main: #541cf5;
}

.home-section {
  width: 100rem;
  height: 140rem;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.home-upper-section {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  justify-content: space-between;
}

.home-heading1 {
  font-family: roboto;
  font-weight: 700;
  line-height: 2.7rem;
  font-size: 1.2rem;
}

.home-heading2 {
  font-family: roboto;
  font-weight: 700;
  line-height: 2.7rem;
  font-size: 2rem;
}

.home-sub-heading {
  width: 100%;
  display: flex;
  gap: 5rem;
}

.home-btns {
  width: 4rem;
  height: 2.1rem;
  /* margin-top: -3.2rem; */
  /* margin-left: 35rem; */
  display: flex;
  gap: 0.8rem;
}

.home-btns img {
  height: 2.1rem;
  width: 2.1rem;
}

/* progress */
.progress-section {
  margin-top: 2rem;
  width: 29rem;
  height: 12rem;
  padding: 1rem;
}

.progress-heading {
  font-family: roboto;
  font-weight: 700;
  font-size: 1.8rem;
  line-height: 1.65rem;
}

.progress-container {
  width: 100%;
  height: 8.6rem;
  box-shadow: 0px 4px 20px 0px #0000001f;
  border-radius: 1rem;
  margin-top: 0.5rem;
  padding: 0.8rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.progress-sub {
  font-family: roboto;
  font-weight: 700;
  font-size: 1.3rem;
  line-height: 1.5rem;
  margin-top: 0.5rem;
}

.progress-text {
  color: #51ac10;
  font-family: Roboto;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 700;
  line-height: 2.51856rem; /* 201.486% */
}

/* video section */
.video-section {
  margin-top: 2rem;
  width: 90rem;
  height: 30.375rem;
  border-radius: 1rem;
}

/* module section */

/* todo: adjust height for more modules */
.modulesss {
  width: 60rem;
  height: 60rem;
}

.module-img {
  width: 25rem;
  height: 20rem;
  cursor: pointer;
}

.module-main-heading {
  font-family: roboto;
  font-weight: 700;
  font-size: 1.8rem;
  line-height: 1.5rem;
  margin-left: 0;
}

.module-sub {
  font: roboto;
  font-weight: 400;
  font-style: italic;
  font-size: 1rem;
  line-height: 1.6rem;
  margin-top: 0.4rem;
  margin-bottom: 0.5rem;
}

.module-container {
  display: grid;
  max-height: max-content;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: auto;
  gap: 1rem;
}

/* .module {
  width: 30rem;
  height: 36rem;
} */

.module-text {
  width: 25rem;
  height: 6.78488rem;
  padding: 1rem;
  border-radius: 0rem 0rem 1.30481rem 1.30481rem;
  background: #fff;
  box-shadow: 0px 4.17532px 17.7451px 0px rgba(0, 0, 0, 0.08);
}

.module--heading {
  color: #323232;
  font-family: Roboto;
  font-size: 1.48619rem;
  font-style: normal;
  font-weight: 700;
  line-height: 1.92419rem; /* 129.472% */
}

.module-sub-heading {
  color: #616161;
  font-family: Roboto;
  font-size: 1.22706rem;
  font-style: italic;
  font-weight: 400;
  line-height: 1.66819rem; /* 135.946% */
}

@media (max-width: 425px) {
  .home-sidebar {
    display: none;
  }
  .home-section {
    padding: 0;
  }
  .home-heading1 {
    font-size: 1rem;
  }
  .home-heading2 {
    font-size: 1.25rem;
  }
  .home-upper-section {
    padding: 0.5rem;
    gap: 0.5rem;
  }
  .home-sub-heading {
    flex-direction: column;
    gap: 0.5rem;
  }
  .progress-section {
    margin-top: 2rem;
    width: 25rem;
    height: 12rem;
    padding: 1rem;
  }
  .progress-heading {
    font-size: 1.25rem;
  }
  .video-section {
    margin-top: 2rem;
    width: 22.375rem;
    height: 30rem;
    margin-left: -10rem;
    border-radius: 1rem;
  }
  .modulesss {
    padding: 1rem;
  }
  .module-img {
    width: 20rem;
    height: 15rem;
    cursor: pointer;
  }
  .module-text {
    width: 20rem;
  }
}
