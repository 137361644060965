.quiz {
  display: flex;
  overflow-x: hidden;
}

.quiz-section {
  height: 70rem;
}

.quiz-sections-name {
  color: #000;
  font-family: Roboto;
  font-size: 2.125rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1.43919rem;
  width: 70.125rem;
  display: flex;
  justify-content: space-between;
  margin-bottom: 1.5rem;
  border-bottom: 0.0625rem solid #e0e0e0;
}

.quiz-info-icon {
  width: 1.5rem;
  height: 1.5rem;
  /* margin-top: 0.5rem; */
  cursor: pointer;
  margin-left: 1rem;
}

.quiz-img {
  width: 98.1875rem;
  height: 36rem;
}

.quiz-container {
  width: 80rem;
  height: max-content;
  border-radius: 1.25rem;
  background: #fff;
  box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.12);
  padding: 5rem;
  z-index: 10;
  position: absolute;
  margin: -28rem 0 5rem 4rem;
}

.quiz-save {
  display: flex;
  gap: 0.75rem;
  cursor: pointer;
}

.quiz-bookmark,
.quiz-next-arrow,
.correct-ans,
.wrong-ans {
  width: 1.5rem;
  height: 1.5rem;
}

.quiz-save-text {
  width: 3.3125rem;
  color: #000;
  font-family: Roboto;
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 1.6875rem */
  letter-spacing: 0.0225rem;
}

.quiz-section-desc {
  width: 72.5625rem;
  color: #000;
  text-align: center;
  font-family: Roboto;
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 1.6875rem */
  letter-spacing: 0.02813rem;
  margin: 1rem 0;
}

.quiz-question-numbers {
  color: #000;
  text-align: center;
  font-family: Roboto;
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 1.6875rem */
  letter-spacing: 0.02813rem;
  margin: 1rem 0;
}

.quiz-question-container {
  width: 35.2rem;
  height: max-content;
  border-radius: 0.72rem;
  background: #f4f0ff;
  margin-left: 19rem;
}

.quiz-que {
  display: flex;
  gap: 0.5rem;
  width: 31rem;
  height: 3.7rem;
  justify-content: left;
  color: #252625;
  font-family: Roboto;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.9255rem;
  padding: 1rem;
  margin-left: 2rem;
}

.quiz-btns {
  margin: 2.5rem 2rem;
}

.quiz-true-btn,
.quiz-false-btn {
  display: flex;
  justify-content: center;
  gap: 0.5rem;
  width: 31.58975rem;
  height: 3.24925rem;
  padding: 0.9rem 0rem 0.94919rem 0rem;
  flex-shrink: 0;
  border-radius: 6.01706rem;
  /* background: #6F4E9FB8; */
  margin-bottom: 1rem;
  color: #fff;
  cursor: pointer;
  font-family: Roboto;
  font-size: 1.375rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1.44413rem; /* 105.026% */
}

.quiz-explanation {
  width: 31.75731rem;
  color: #000;
  font-family: Roboto;
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 1.575rem */
  text-align: left;
  margin-left: 2rem;
}

.quiz-next-btn {
  display: flex;
  align-items: center;
  gap: 0.4rem;
  width: 15rem;
  height: 2.3rem;
  margin-left: 10rem;
  padding-top: 1rem;
  padding-bottom: 1.75rem;
  justify-content: center;
  color: #6f4e9f;
  font-family: Roboto;
  font-size: 1.375rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.9255rem; /* 140.034% */
  cursor: pointer;
}

.quiz-footer-btns {
  display: flex;
  /* justify-content: space-between; */
  gap: 50rem;
  margin-top: 2.5rem;
  cursor: pointer;
}

@media (max-width: 425px) {
  .quiz {
    overflow-x: hidden;
  }
  .quiz-section {
    height: 50rem;
  }
  .quiz-sidebar,
  .quiz-bg-img,
  .quiz-save-text {
    display: none;
  }
  .quiz-container {
    width: 100%;
    height: max-content;
    border-radius: 1.25rem;
    background: #fff;
    padding: 1rem;
    margin: 2rem 0 0 0;
  }
  .quiz-section-desc {
    width: 100%;
    text-align: left;
    font-size: 1rem;
    line-height: 140%; /* 1.6875rem */
    letter-spacing: 0.0175rem;
    margin: 1rem 0;
  }
  .quiz-question-numbers {
    font-size: 1rem;
    margin: 1rem -1rem;
  }
  .quiz-question-container {
    width: 22rem;
    height: max-content;
    border-radius: 0.72rem;
    background: #f4f0ff;
    margin-left: 0;
  }
  .quiz-que {
    width: 19.375rem;
    height: 2.3125rem;
    justify-content: left;
    line-height: 1.20344rem;
    font-size: 1rem;
    padding: 1rem;
    margin-left: 0.5rem;
  }
  .quiz-btns {
    margin: 2.5rem 1rem;
  }
  .quiz-true-btn,
  .quiz-false-btn {
    display: flex;
    justify-content: center;
    width: 19.74356rem;
    height: 2.03075rem;
    padding: 0.5625rem 0rem 0.59325rem 0rem;
    margin-bottom: 1rem;
    font-size: 1rem;
    line-height: 0.90256rem; /* 105.026% */
  }
  .quiz-explanation {
    width: 19.75rem;
    font-size: 1rem;
    margin-top: -0.5rem;
    margin-left: 2rem;
  }
  .quiz-next-btn {
    gap: 0.4rem;
    width: 15rem;
    height: 2.3rem;
    margin-left: 3.5rem;
    margin-top: 1.2rem;
    padding-bottom: 1.75rem;
    font-size: 1rem;
    line-height: 1.20344rem; /* 140.034% */
  }
  .quiz-bookmark,
  .quiz-next-arrow,
  .correct-ans,
  .wrong-ans {
    width: 1.2rem;
    height: 1.2rem;
  }
  .quiz-footer-btns {
    gap: 3rem;
  }
}
