.sidebar-section {
  width: 20rem;
  height: 62rem;

  box-shadow: 0px 4px 48px 0px #0000001c;
}

.sidebar-list {
  width: 20.5rem;
  height: 39.25rem;
  padding: 1rem 2rem 0 0;
}

.sidebar-li {
  text-decoration: none;
  text-decoration-line: none;
  display: flex;
  flex-direction: row;
  gap: 1rem;
  padding: 1rem 3rem;
  cursor: pointer;
}

.sidebar-li:hover {
  border-left: 4px solid rgba(179, 153, 253, 0.34);
  background: linear-gradient(
    90deg,
    rgba(179, 153, 253, 0.34) 0%,
    rgba(237, 223, 255, 0) 100%
  );
  animation: slide 2s ease-in-out infinite; /* Make the animation infinite if needed */
}

@keyframes slide {
  to {
    left: 100%; /* Move the gradient to the right edge */
  }
}

.sidebar-li p {
  text-decoration: none;
  text-decoration-line: none;
  color: #000;
  font-family: inter;
  font-weight: 500;
  font-size: 1.1rem;
  line-height: 1.5rem;
  letter-spacing: 0.1rem;
}

.sidebar-li img {
  width: 24px;
  height: 24px;
}

.sidebar-app {
  width: 14.375rem;
  height: 20rem;
  gap: 9px;
  align-items: center;
  justify-items: center;
  margin-top: -15rem;
  display: flex;
  flex-direction: column;
}

.sidebar-app-heading {
  margin-left: 35%;
  display: flex;
  gap: 0.5rem;
}

.sidebar-app-heading > p {
  font-family: inter;
  font-weight: 500;
  font-size: 1.1rem;
  line-height: 1.5rem;
  letter-spacing: 0.1rem;
}

.sidebar-app-qr {
  margin-left: 40%;
}

.sidebar-qr-text {
  width: 150%;
  font-family: inter;
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.5rem;
  letter-spacing: 0.1rem;
  text-align: center;
  margin-left: -20%;
}
