.modules-section {
  width: 95vw;
  height: 80rem;
}

.modules-upper-section {
  display: none;
}

.modules-bg-img {
  width: 90rem;
  height: 36rem;
  min-height: 30rem;
  /* margin-top: -12rem; */
}

.modules-container {
  width: 65.25rem;
  height: auto;
  border-radius: 1.25rem;
  border-radius: 1.25rem;
  background: #fff;
  box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.12);
  padding: 2.5rem;
  margin: -25rem 7rem 4rem 10rem;
  z-index: 10;
  position: absolute;
}

.modules-heading {
  width: 250%;
  color: #343434;
  font-family: Roboto;
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: 2rem; /* 200% */
  display: flex;
  letter-spacing: 0.025rem;
}

.modules-sub-heading {
  width: 60rem;
  height: 2rem;
  color: #000;
  font-family: Roboto;
  font-size: 2.125rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1.44rem; /* 67.727% */
  margin-top: 1rem;
  border-bottom: 0.0625rem solid #e0e0e0;
}

/* .line {
  width: 62.4375rem;
  height: 0.0625rem;
  background: #e0e0e0;
  margin-top: 5rem;
} */

.modules-heading-span {
  color: var(--main);
  text-decoration-line: underline;
}

.modules-sections-heading {
  margin: 2rem 0;
  color: #6d6d6d;
  font-family: Roboto;
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.43919rem; /* 127.928% */
  letter-spacing: 0.03125rem;
}

.modules-sections-item {
  cursor: pointer;
  display: flex;
  padding: 1.75rem 2.25rem;
  margin: 1.25rem 0;
  justify-content: space-between;
  gap: 0.625rem;
  border-radius: 0.4375rem;
  background: #fff;
  color: #000;
  box-shadow: 0px 4px 24px 0px rgba(0, 0, 0, 0.1);
}

.modules-sections-item:hover {
  border-radius: 0.4375rem;
  background: linear-gradient(90deg, #541cf5 0.09%, #3405ba 99.93%);
  box-shadow: 0px 4px 24px 0px rgba(0, 0, 0, 0.1);
  color: #fff;
}

.modules-sections-item:hover .modules-icons,
.modules-sections-item:hover .modules-text,
.modules-sections-item:hover .modules-bookmark {
  color: #fff;
}

.items {
  display: flex;
  gap: 0.625rem;
}

.modules-icons,
.modules-bookmark {
  width: 1.5rem;
  height: 1.5rem;
  color: #000000;
}

.modules-icons {
  margin-top: -0.5rem;
}

.modules-bookmark {
  justify-content: space-between;
}

.modules-text {
  width: 100%;
  height: 1.75rem;
  color: #000;
  font-family: Roboto;
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1.44rem; /* 127.928% */
  letter-spacing: 0.03125rem;
  margin-top: 0.1rem;
  transition: color 0.3s;
}

/* .modules-text:hover {
  color: #fff;
} */

.modules-btn {
  display: flex;
  height: 2rem;
  padding: 0.625rem 0.25rem;
  justify-content: flex-end;
  align-items: right;
  gap: 0.625rem;
  cursor: pointer;
}

.modules-btn-text {
  color: #333;
  font-family: Roboto;
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-align: right;
}

.arrow {
  width: 2.1875rem;
  height: 2.1875rem;
  margin-top: -0.1rem;
  border-radius: 100%;
  margin-top: -0.5rem;
  box-shadow: 0px 2.5px 8.125px 0px #00000030;
}

.arrow-img {
  width: 0.9375rem;
  height: 0.9375rem;
  margin-left: 0.5rem;
  margin-top: 0.2rem;
}

@media (max-width: 425px) {
  .modules-sidebar {
    display: none;
  }
  .modules-upper-section {
    padding: 0.5rem;
    gap: 0.5rem;
  }
  .modules-container {
    width: 90%;
    height: max-content;
    margin: -25rem 1rem 2rem 1rem;
  }
  /* .modules-heading {
    width: 90%;
  } */
  .modules-bg-img {
    margin-top: 15rem;
    height: 20rem;
  }
  .modules-btn {
    gap: 1rem;
  }
}
