.faq-section {
  width: 100%;
  height: auto;
  max-height: 100vh;
  background: linear-gradient(
    103.69deg,
    #f1f5ff 0%,
    rgba(255, 248, 243, 0) 108.17%
  );
  margin-top: 2rem;
}

.faq-container {
  width: 100%;
  height: 100%;
  padding: 1rem 15rem;
}

.faq-heading {
  color: #000;
  font-family: Roboto;
  font-size: 3rem;
  font-style: normal;
  font-weight: 700;
  line-height: 3rem; /* 100% */
}

.item {
  padding: 1rem 0;
  width: 100%;
}

.faq-title {
  width: 100%;
  font: inter;
  font-weight: 700;
  font-size: 1.5rem;
  line-height: 1.2rem;
  /* padding: 0.5rem 0; */
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}

.faq-question {
  font-size: 1.5rem;
}

.faq-answer {
  font: inter;
  font-weight: 400;
  font-size: 1.1rem;
  line-height: 1.7rem;
  max-height: 0;
  overflow: hidden;
  transition: all 0.5s cubic-bezier(0, 1, 0, 1);
}

.faq-answer.show {
  height: auto;
  max-height: 999px;
  transition: all 0.5s cubic-bezier(1, 0, 1, 0);
}

.item-border {
  border-bottom: 2px solid #f1f5ff;
}

.faq-icon {
  width: 1.5rem;
  height: 1.5rem;
}

@media (max-width: 425px) {
  .faq-section {
    margin-top: 5rem;
    width: 100%;
    height: auto;
    max-height: 150vh;
  }
  .faq-container {
    padding: 0.5rem 1rem;
  }
  .faq-heading {
    margin-top: 1rem;
    font-size: 2.5rem;
    line-height: 1.5rem;
    text-align: center;
  }
  .faq-title {
    font-size: 1.25rem;
    line-height: 1.2rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 4rem;
    /* padding: 0.5rem 0; */
  }
  .faq-question {
    font-size: 1.25rem;
  }
  .faq-btn {
    margin-top: -2rem;
    margin-left: -2rem;
  }
}
