.journal {
  overflow-x: hidden;
  display: flex;
}

.journal-section {
  height: 74rem;
}

.journal-container {
  width: 82.375rem;
  height: 63rem;
  border-radius: 1.25rem;
  background: #fff;
  box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.12);
  padding: 3rem;
  position: absolute;
  z-index: 10;
  margin: -15rem 0 2rem 2rem;
}

.journal-sections-heading {
  color: #343434;
  font-family: Roboto;
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: 2rem; /* 200% */
  letter-spacing: 0.025rem;
}

.journal-sections-heading-span {
  color: #6f4e9f;
}

.journal-section-name {
  color: #000;
  font-family: Roboto;
  font-size: 2.125rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1.43919rem;
  width: 70.125rem;
  display: flex;
  justify-content: space-between;
  margin-bottom: 1.5rem;
  border-bottom: 0.0625rem solid #e0e0e0;
}

.journal-entries {
  display: flex;
  gap: 3rem;
}

.new-entry {
  width: 100%;
}

.new-entry-title {
  width: 16.4375rem;
  color: #252625;
  text-align: center;
  font-family: Roboto;
  font-size: 1.375rem;
  font-style: normal;
  font-weight: 700;
  line-height: 150%; /* 2.0625rem */
}

.new-entry-input-title {
  margin: 2rem 0.75rem;
  width: 36.125rem;
  height: 3rem;
  border-radius: 0.675rem;
  background: #fff;
  box-shadow: 0px 4.8px 18px 0px rgba(0, 0, 0, 0.09);
}

.title-input,
.text-input {
  border: none;
  margin-top: 0.5rem;
  margin-left: 1rem;
  width: 17.82738rem;
  height: 1.8rem;
  color: #9c9c9c;
  font-family: Roboto;
  font-size: 1rem;
  font-style: italic;
  font-weight: 400;
  line-height: 100%; /* 1.56rem */
}

.text-input {
  margin-top: 1rem;
  width: 35rem;
  height: 28rem;
  overflow: hidden;
}

.new-entry-input-desc {
  width: 36.125rem;
  height: 28.6875rem;
  border-radius: 0.675rem;
  background: #fff;
  box-shadow: 0px 4.8px 18px 0px rgba(0, 0, 0, 0.09);
  margin: 2rem 0.75rem;
  overflow: hidden;
}

.new-entry-btn {
  width: 6.5rem;
  height: 3.25rem;
  margin-top: 1rem;
  margin-left: 1rem;
  gap: 1.5px;
  border-radius: 54px;
  padding: 0.8rem;
  background-color: #6f4e9f;
  font-family: helvetica;
  font-weight: 700;
  font-size: 1.1rem;
  line-height: 1.5rem;
  color: white;
  text-align: center;
}

.previous-entries {
  width: 100%;
  height: 37rem;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
}

.prev-entries-header {
  display: flex;
  flex-direction: row;
  width: 100%;
  /* align-items: center; */
  gap: 12.125rem;
}

.prev-entries-title {
  color: #100f11;
  font-family: Roboto;
  font-size: 1.375rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.prev-entries-date {
  /* width: 9.625rem; */
  height: 1.5625rem;
  color: #000;
  font-family: Roboto;
  font-size: 1.2rem;
  font-style: normal;
  font-weight: 500;
  line-height: 130%;
  text-decoration-line: underline;
}

.calendar-icon {
  display: none;
}

.prev-entries-items {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  margin-top: 1.5rem;
}

.prev-entries-item {
  padding: 1rem;
  width: 32.4375rem;
  height: max-content;
  border-radius: 0.49231rem;
  background: #fff;
  box-shadow: 0px 4.50137px 27.00824px 0px rgba(0, 0, 0, 0.1);
}

.prev-item-date {
  width: 3.6rem;
  color: #8a8a8a;
  font-family: Roboto;
  font-size: 0.9rem;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 1.35rem */
  letter-spacing: 0.018rem;
}

.prev-item-content {
  display: flex;
  width: 30.625rem;
}

.prev-item-text {
  display: flex;
  flex-direction: column;
}

.prev-item-title {
  display: flex;
  gap: 0.5rem;
}

.prev-item-title-text {
  width: 12.25rem;
  color: #6f4e9f;
  font-family: Roboto;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 1.875rem */
  letter-spacing: 0.025rem;
}

.prev-item-desc {
  color: #939393;
  font-family: Roboto;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 1.875rem */
  letter-spacing: 0.03125rem;
  width: 25rem;
  margin-left: 1.5rem;
  text-align: left;
  max-height: 0;
  overflow: hidden;
  transition: all 1.4s ease-in-out;
}
.prev-item-desc.show {
  height: auto;
  max-height: 9999px;
  transition: all 1.4s ease-in-out;
}

.see-more-icon {
  width: 0.9rem;
  height: 0.45rem;
  margin-top: 0.65rem;
  cursor: pointer;
}

.prev-item-icons {
  display: flex;
  gap: 0.75rem;
}

.edit-icon,
.dlt-icon {
  width: 1.4rem;
  height: 1.4rem;
  cursor: pointer;
}

@media (max-width: 425px) {
  .journal-section {
    height: 89rem;
  }
  .journal-sidebar,
  .journal-bg-img {
    display: none;
  }
  .journal-container {
    height: 90rem;
    width: 100%;
    margin: 2rem 0 0 1rem;
    height: max-content;
    padding: 0;
    box-shadow: none;
  }
  .journal-sections-heading {
    font-size: 0.875rem;
  }
  .journal-entries {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
  .new-entry-title {
    font-size: 1rem;
    line-height: 150%;
    margin-left: -2.5rem;
    letter-spacing: 0.0218rem;
  }
  .new-entry-input-title {
    margin: 1rem 0 0 0.25rem;
    width: 90%;
  }
  .new-entry-input-desc {
    width: 90%;
    height: 20rem;
    margin: 1rem 0 0 0.25rem;
  }
  .text-input {
    width: 90%;
  }
  .previous-entries {
    margin-top: 1rem;
    margin-left: 0.5rem;
  }
  .prev-entries-header {
    width: 100%;
    gap: 5rem;
  }
  .prev-entries-title {
    font-size: 1.055rem;
  }
  .prev-entries-date {
    font-size: 0.93781rem;
    display: flex;
    gap: 0.5rem;
  }
  .calendar-icon {
    display: block;
    width: 1.4rem;
    height: 1.4rem;
  }
  .prev-entries-item {
    padding: 1rem;
    width: 21.625rem;
    height: max-content;
    border-radius: 0.41031rem;
    background: #fff;
    box-shadow: 0px 3.75114px 22.50687px 0px rgba(0, 0, 0, 0.1);
  }
  .prev-item-content {
    display: flex;
    width: 70%;
    justify-content: left;
  }
  .prev-item-title-text {
    width: 9.625rem;
    font-size: 1rem;
    letter-spacing: 0.02rem;
  }
  .prev-item-desc {
    font-size: 1rem;
    letter-spacing: 0.002188rem;
    width: 18.125rem;
    margin-left: 1.25rem;
    text-align: left;
  }
  .prev-item-icons {
    margin-left: -1.5rem;
  }
  .edit-icon,
  .dlt-icon {
    width: 1.6rem;
    height: 1.5rem;
  }
}

/* textarea
{
  line-height: 4ch;
  background-image: linear-gradient(transparent, transparent calc(4ch - 1px), #E7EFF8 0px);
  background-size: 100% 4ch;
} */
