
.custom-font {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 130%;
 
}

.custom-card {
  border: 2px solid #f0f0f0;
  border-radius: 10px;
  transition: border-color 0.3s; 
  width: 261px;
  height: 222px;
}

.custom-card:hover {
  border-color: rgb(27, 139, 207);
  box-shadow: 3px 3px 5px rgba(27, 139, 207, 0.3);
}

.custom-card-1{
  width: 261px;
  height: 146px;
  display: flex;

}

