.sessionCards--Container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(260px, 1fr));
  padding: 30px 50px;
  .card {
    border: 2px solid #f0f0f0;
    border-radius: 10px;
    transition: border-color 0.3s;
  }
}
.sessionManagement--Dashboard {
  .pagination {
    width: max-content;
    margin: auto;
    gap: 10px;
  }
  .pagination__link--active {
    a {
      padding: 8px 14px !important;
      background: #1b8bcf !important;
      color: #fff;
    }
  }
}
