.related-course-section {
  width: 80%;
  height: 80vh;
}

.related-course-container {
  width: 100%;
  padding: 1rem;
  margin-left: 10rem;
  position: relative;
}

.related-course-heading {
  font: roboto;
  font-weight: 700;
  font-size: 3rem;
  line-height: 3rem;
  text-align: center;
  margin-left: 5rem;
}

.related-course-desc {
  padding: 2rem 0;
  font: roboto;
  font-weight: 400;
  font-size: 1.5rem;
  line-height: 2.1rem;
  text-align: center;
  margin-left: 10rem;
}

.related-course-cards {
  width: 60vw;
  margin-left: 10rem;
}

.related-course-card {
  display: flex;
  gap: 2rem;
  margin: 2rem 5rem;
}

.arrows {
  width: 80%;
  display: flex;
  justify-content: space-between;
  margin-left: 4.5rem;
  position: absolute;
  top: 70%;
  cursor: pointer;
}

.course-card-container {
  width: 20rem;
  height: 25rem;
  border-radius: 1rem;
  border: 2px solid #ebebeb;
  padding: 1rem;
  box-shadow: 0px 0px 10px 0px #00000033;
}

.course-card-title {
  font: inter;
  font-weight: 600;
  width: 18.1875rem;
  height: 3rem;
  min-height: 3rem;
  font-size: 1.3rem;
  line-height: 2rem;
  color: #101828;
}

.course-details {
  display: flex;
  justify-content: space-between;
  padding: 1rem 0;
}

.course-selfPaced {
  width: 8rem;
  height: 2.5rem;
  border-radius: 1.1rem;
  padding: 0.3rem;
  justify-content: center;
  align-items: center;
  gap: 0.2rem;
  background: #9fd68b1a;
  display: flex;
  font: inter;
  font-weight: 500;
  font-size: 1.05rem;
  line-height: 1.1rem;
  text-align: center;
  color: #4c9234;
}

.course-buttons {
  display: flex;
  gap: 0.2rem;
}

.course-card-btn {
  width: 18rem;
  height: 3rem;
  border-radius: 54px;
  padding: 0.7rem 3rem;
  background-color: #fd6f1f;
  font: helvetica;
  font-weight: 700;
  font-size: 1.1rem;
  line-height: 1.5rem;
  color: white;
  text-align: center;
  display: flex;
  justify-content: space-evenly;
  cursor: pointer;
}

.swiper-button-next,
.swiper-button-prev {
  width: 110%;
  display: flex;
  justify-content: space-between;
  background-color: transparent;
  color: #541cf5;
  position: absolute;
  top: 50%;
  cursor: pointer;
}

.swiper-button-next {
  margin-left: 4rem;
}

.swiper-button-prev {
  margin-left: 0rem;
}

.custom-arrow {
  width: 20px; /* Adjust the width as needed */
  height: 20px; /* Adjust the height as needed */
  color: #541cf5;
  /* color: white; */
  font-weight: 500;
  border-radius: 100%;
  font-size: 20px;
  text-align: center;
  line-height: 40px;
  cursor: pointer;
}

@media (max-width: 425px) {
  .related-course-container {
    width: 90%;
    padding: 1.5rem 0;
    position: relative;
  }
  .related-course-heading {
    font-size: 1.8rem;
    line-height: 3rem;
    text-align: center;
    width: 100%;
    margin-left: -7rem;
  }
  .related-course-desc {
    margin-left: -9rem;
    padding: 1.5rem;
    width: 130%;
    font-size: 1rem;
    line-height: 1.5rem;
    text-align: center;
  }
  .related-course-cards {
    width: 100vw;
    margin-left: -10rem;
  }
  .related-course-card {
    display: flex;

    margin: 2rem;
  }
  .course-card-container {
    width: 20rem;
    height: 25rem;
    margin-left: 10%;
    border-radius: 1rem;
    border: 2px solid #ebebeb;
    /* padding: 1rem; */
    box-shadow: 0px 0px 10px 0px #00000033;
  }
  .course-card-image {
    width: 100%;
    height: 5rem;
  }
  .course-card-title {
    font-size: 1.2rem;
    line-height: 1.5rem;
    font-weight: 600;
    margin-top: 6rem;
  }
  .course-details {
    margin-top: 0.5rem;
    padding: 0.5rem 0;
  }
  .course-card-btn {
    width: 80%;
    height: 3.5rem;
    border-radius: 54px;
    padding: 0.7rem 1rem;
    background-color: #fd6f1f;
    font: helvetica;
    font-weight: 700;
    font-size: 1.2rem;
    line-height: 1.25rem;
    color: white;
    justify-items: center;
    align-items: center;
    text-align: center;
    display: flex;
    justify-content: space-evenly;
    margin-left: 2rem;
  }
  .swiper-button-next,
  .swiper-button-prev {
    width: 100%;
    display: flex;
    justify-content: space-between;
    background-color: transparent;
    color: #541cf5;
    position: absolute;
    top: 50%;
    cursor: pointer;
  }
  .swiper-button-next {
    margin-left: 1rem;
  }
  .swiper-button-prev {
    margin-left: 1rem;
  }
  .custom-arrow {
    width: 20px; /* Adjust the width as needed */
    height: 20px; /* Adjust the height as needed */
    color: #541cf5;
    /* color: white; */
    font-weight: 500;
    border-radius: 100%;
    font-size: 20px;
    text-align: center;
    line-height: 40px;
    cursor: pointer;
  }
}
