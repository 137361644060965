@charset "UTF-8";
:root {
  --primary: #000;
  --secondary: #fff;
  --clrblue: #541cf5;
  --clrorange: #f25d0f;
  --clrsky: #1ae9ff;
  --clrgreen: #aef05e;
  --ftbg: #c6b1ff;
}

.new-plans {
  /* ********* Main CSS************** */
  /* ********* Top-Nav-Area CSS************** */
  /* ********* Nav-Area CSS************** */
  /* ********* Hero-Area CSS************** */
  /* ********* Personal-Data-Area CSS************** */
  /* ********* Pick-Plan-Area CSS************** */
  /* ********* Compare-Plan-Area CSS************** */
  /* ********* Choice-Area CSS************** */
  /* ********* Trust-Area CSS************** */
  /* ********* Reason-Area CSS************** */
  /* ********* Partner-Area CSS************** */
  /* ********* Client-Area CSS************** */
  /* ********* Best-Area CSS************** */
  /* ********* Review-Area CSS************** */
  /* ********* Team-Area CSS************** */
  /* ********* FAQ-Area CSS************** */
  /* ********* Testimonial-Area CSS************** */
  /* ********* Footer-Area CSS************** */
  /* ********* Copyright-Area CSS************** */
  /* -----------------------
          RESPONSIVE
   ---------------------- */
  /* For Mobile Phones View */
}
.new-plans a {
  color: var(--bs-blue);
  text-decoration: none;
}
.new-plans ul {
  margin: 0;
  padding: 0;
}
.new-plans h2.heading {
  font-family: Roboto;
  font-size: 65px;
  font-weight: 600;
  line-height: 90px;
  letter-spacing: 0px;
  text-align: center;
  color: var(--primary);
  width: 100%;
  float: left;
}
.new-plans h2.heading1 {
  font-family: Roboto;
  font-size: 65px;
  font-weight: 600;
  line-height: 90px;
  letter-spacing: 0px;
  text-align: center;
  color: var(--secondary);
}
.new-plans .text-lf {
  text-align: left !important;
}
.new-plans .cl-wh {
  color: var(--secondary) !important;
}
.new-plans .desktop-display-none {
  display: none;
}
.new-plans .desktop-display {
  display: block;
}
.new-plans .main {
  width: 100%;
  float: left;
}
.new-plans .top-nav {
  width: 100%;
  float: left;
  background-color: var(--primary);
  height: 44px;
}
.new-plans .top-nav-text {
  width: 100%;
  float: left;
}
.new-plans .top-nav-text p {
  color: var(--secondary);
  font-family: "Roboto";
  font-weight: 400;
  font-size: 14px;
  line-height: 18.75px;
  text-align: right;
  margin-top: 13px;
}
.new-plans .top-nav-btn {
  width: 100%;
  float: left;
}
.new-plans .top-nav-btn button.btn {
  width: 35%;
  border-radius: 23px;
  border: 1px solid var(--secondary);
  float: left;
  padding: 2px;
  margin-top: 7px;
}
.new-plans .top-nav-btn button.btn a {
  color: var(--secondary);
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: -0.005em;
  text-align: center;
}
.new-plans .nav-item {
  cursor: pointer;
}
.new-plans .nav-item:hover .dropdown-menu {
  display: block !important;
}
.new-plans a.dropdown-item {
  font-family: "Roboto";
}
.new-plans .nav-area {
  width: 100%;
  float: left;
}
.new-plans a.navbar-brand {
  width: 20%;
  float: left;
}
.new-plans a.nav-link {
  font-family: "Roboto";
  font-size: 17px;
}
.new-plans a.navbar-brand img {
  width: 85%;
}
.new-plans .nav-login-btn {
  width: 10%;
  float: left;
}
.new-plans .nav-login-btn button.btn {
  background-color: var(--clrblue);
  padding: 5px 25px;
  border-radius: 25px;
  color: var(--secondary);
}
.new-plans .nav-login-btn button.btn a {
  color: var(--secondary);
  font-family: "Roboto";
  font-size: 15px;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: center;
}
.new-plans .navbar-toggler:focus {
  text-decoration: none;
  outline: 0;
  box-shadow: none;
}
.new-plans .search-container {
  width: 308px;
  /* display: block; */
  /* margin: 0 auto; */
  float: left;
  height: 45px;
}
.new-plans input#search-bar {
  margin: 0 auto;
  width: 100%;
  height: 45px;
  padding: 0 20px;
  font-size: 1rem;
  border: 1px solid #541cf5;
  outline: none;
  border-radius: 30px;
  font-family: "Lato";
}
.new-plans input#search-bar focus {
  border: 1px solid #008abf;
  transition: 0.35s ease;
  color: #008abf;
}
.new-plans input#search-bar focus::-webkit-input-placeholder {
  -webkit-transition: opacity 0.45s ease;
  transition: opacity 0.45s ease;
  opacity: 0;
}
.new-plans input#search-bar focus::-moz-placeholder {
  -moz-transition: opacity 0.45s ease;
  transition: opacity 0.45s ease;
  opacity: 0;
}
.new-plans input#search-bar focus:-ms-placeholder {
  -ms-transition: opacity 0.45s ease;
  transition: opacity 0.45s ease;
  opacity: 0;
}
.new-plans .search-icon {
  position: relative;
  float: right;
  width: 25px;
  height: 25px;
  top: -36px;
  right: 11px;
}
.new-plans .hero-area {
  width: 100%;
  float: left;
  background-image: url(../../../public/assets/img/bgsec.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 90px 0;
  height: 740px;
}
.new-plans .hero-area.blueGradient {
  background-image: url(../../../public/assets/img/bgSecBlue.png);
}
.new-plans .hero-text {
  width: 100%;
  float: left;
  padding-right: 80px;
}
.new-plans .hero-img {
  width: 100%;
  float: left;
}
.new-plans .img1 {
  width: 100%;
  float: left;
  margin-left: -90px;
}
.new-plans .img1 img {
  width: 100%;
  float: left;
  margin-top: 93px;
}
.new-plans .img2 {
  width: 23%;
  float: left;
  position: absolute;
  right: 15.9%;
}
.new-plans .img2 img {
  width: 100%;
  float: left;
}
.new-plans .img3 {
  width: 100%;
  float: left;
}
.new-plans .img3 img {
  width: 100%;
  float: left;
  margin-top: 13px;
}
.new-plans .img4 {
  width: 100%;
  float: left;
}
.new-plans .img4 img {
  width: 100%;
  float: left;
}
.new-plans .img5 {
  width: 100%;
  float: left;
}
.new-plans .img5 img {
  width: 100%;
  float: left;
  margin-top: 20px;
}
.new-plans .hero-text h1 {
  font-family: Roboto;
  font-size: 65px;
  font-weight: 600;
  line-height: 84px;
  letter-spacing: 0px;
  text-align: left;
  color: var(--primary);
}
.new-plans span.diff-clr {
  color: var(--clrblue);
}
.new-plans .hero-text h4 {
  font-family: Roboto;
  font-size: 23px;
  font-weight: 700;
  line-height: 47px;
  letter-spacing: 0em;
  text-align: left;
  margin-bottom: 35px;
}
.new-plans .icon-area {
  width: 100%;
  float: left;
}
.new-plans .icon-area h5 {
  font-family: Roboto;
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0px;
  text-align: left;
  width: 100%;
  float: left;
}
.new-plans .icon-area p {
  font-family: Roboto;
  font-size: 16px;
  font-style: italic;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0px;
  text-align: left;
  width: 100%;
  float: left;
}
.new-plans .icon-bg {
  background-color: var(--secondary);
  width: 30%;
  padding: 14px;
  border-radius: 53px;
  margin-bottom: 20px;
}
.new-plans .icon-bg img {
  width: 100%;
}
.new-plans .Subscribe-btn {
  width: 100%;
  float: left;
  display: flex;
}
.new-plans .Subscribe-btn button.btn {
  background-color: var(--clrorange);
  padding: 13px 45px;
  border-radius: 44px;
  color: var(--secondary);
}
.new-plans .Subscribe-btn button.btn a {
  color: var(--secondary);
  font-family: "Roboto";
  font-size: 18px;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: center;
}
.new-plans .btm-text {
  font-family: Roboto;
  font-size: 19px;
  font-weight: 700;
  line-height: 30px;
  letter-spacing: 0em;
  text-align: left;
}
.new-plans .whats-app {
  width: 100%;
  float: right;
  position: fixed;
  bottom: 30px;
}
.new-plans .whats-app img {
  text-align: right;
  float: right;
  margin-top: -30px;
  width: 7%;
}
.new-plans .personal-data-area {
  width: 100%;
  float: left;
  margin-top: -70px;
}
.new-plans .personal-data-inner {
  width: 100%;
  float: left;
  padding: 30px 60px 20px 60px;
  border-radius: 7px;
}
.new-plans .personal-data-text {
  width: 100%;
  float: left;
}
.new-plans .personal-data-img {
  width: 100%;
  float: right;
  margin-top: 0;
}
.new-plans .personal-data-img ul {
  width: 100%;
  float: right;
}
.new-plans .personal-data-img ul li {
  list-style: none;
  display: inline-block;
}
.new-plans .personal-data-img ul li img {
  width: 77%;
  float: right;
}
.new-plans .personal-data-text h3 {
  font-family: "Roboto";
  font-size: 32px;
  font-weight: 600;
  line-height: 43px;
  letter-spacing: 0em;
  text-align: left;
  margin-top: 30px;
}
.new-plans .pick-plan-area {
  width: 100%;
  float: left;
  margin: 40px 0;
}
.new-plans .plan-bg {
  box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.1607843137);
  background-image: url(../../../public/assets/img/planFree_1.png);
  background-repeat: no-repeat;
  border-radius: 7px;
  padding: 35px 30px;
  height: 704px;
  margin: 20px 0px 20px 10px;
}
.new-plans .plan h3 {
  font-family: "Roboto";
  font-size: 32px;
  font-weight: 700;
  line-height: 38px;
  letter-spacing: 2px;
  text-align: left;
}
.new-plans .plan {
  width: 100%;
  float: left;
}
.new-plans .plan h6 {
  font-family: "Roboto";
  font-size: 14px;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: left;
  margin-bottom: 20px;
}
.new-plans .plan p {
  font-family: "Roboto";
  font-size: 16px;
  font-weight: 400;
  line-height: 29px;
  letter-spacing: 0em;
  text-align: left;
}
.new-plans span.price {
  font-family: "Roboto";
  font-size: 28px;
  font-weight: 700;
  line-height: 29px;
  letter-spacing: 0em;
  text-align: left;
}
.new-plans .plan h4 {
  font-family: "Roboto";
  font-size: 16px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
}
.new-plans .plan ul {
  width: 100%;
  float: left;
}
.new-plans .plan ul li {
  list-style: none;
  border-bottom: 1px solid #ddd;
  padding: 7px 0;
}
.new-plans .plan ul li img {
  width: 7%;
  float: left;
  margin-right: 4%;
  margin-top: 3px;
}
.new-plans .plan ul li h5 {
  font-family: Roboto;
  font-size: 20px;
  font-weight: 600;
  line-height: 25px;
  letter-spacing: 0em;
  text-align: left;
}
.new-plans .plan ul li p {
  margin-left: 12%;
  font-family: Roboto;
  font-size: 17px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  color: var(--primary);
  margin-bottom: 6px;
}
.new-plans .plan-btn {
  width: 100%;
  float: left;
}
.new-plans .plan-btn button.btn {
  background-color: var(--clrorange);
  padding: 9px 45px;
  border-radius: 44px;
  color: var(--secondary);
  margin: 96px auto 0;
  display: block;
}
.new-plans .plan-btn button.btn a {
  color: var(--secondary);
  font-family: "Roboto";
  font-size: 18px;
  font-weight: 500;
  line-height: 28px;
  letter-spacing: 0em;
  text-align: center;
}
.new-plans hr {
  width: 70%;
  color: #ffffff;
  border-top: 2px solid;
  margin-top: 0px;
}
.new-plans .plan-bg1 {
  box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.1607843137);
  border-radius: 7px;
  padding: 30px 30px;
  height: 760px;
  background-image: url(../../../public/assets/img/plan1_1.png);
  background-position: right;
  background-repeat: no-repeat;
  background-size: cover;
}
.new-plans .plan1 {
  width: 100%;
  float: left;
}
.new-plans .plan1 h3 {
  font-family: "Roboto";
  font-size: 30px;
  font-weight: 700;
  line-height: 38px;
  letter-spacing: 2px;
  text-align: left;
  color: var(--secondary);
}
.new-plans .plan1 h6 {
  font-family: "Roboto";
  font-size: 14px;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: left;
  color: var(--secondary);
  margin-bottom: 16px;
}
.new-plans .plan1 p {
  font-family: "Roboto";
  font-size: 16px;
  font-weight: 400;
  line-height: 29px;
  letter-spacing: 0em;
  text-align: left;
  color: var(--secondary);
  margin-bottom: 1px;
}
.new-plans span.price {
  font-family: "Roboto";
  font-size: 28px;
  font-weight: 700;
  line-height: 29px;
  letter-spacing: 0em;
  text-align: left;
}
.new-plans .plan1 h4 {
  font-family: "Roboto";
  font-size: 16px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
  color: var(--secondary);
}
.new-plans .plan1 ul {
  width: 100%;
  float: left;
}
.new-plans .plan1 ul li {
  list-style: none;
  border-bottom: 1px solid #ddd;
  padding: 7px 0;
}
.new-plans .plan1 ul li img {
  width: 7%;
  float: left;
  margin-right: 4%;
  margin-top: 3px;
}
.new-plans .plan1 ul li h5 {
  font-family: Roboto;
  font-size: 18px;
  font-weight: 600;
  line-height: 25px;
  letter-spacing: 0em;
  text-align: left;
  color: var(--secondary);
}
.new-plans .plan1 ul li p {
  margin-left: 12%;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
  color: var(--secondary);
  margin-bottom: 6px;
}
.new-plans .plan1-btn {
  width: 100%;
  float: left;
}
.new-plans .plan1-btn button.btn {
  background-color: var(--clrorange);
  border-radius: 44px;
  color: var(--secondary);
  margin: 40px auto;
}
.new-plans .plan1-btn button.btn a {
  color: var(--secondary);
  font-family: "Roboto";
  font-size: 18px;
  font-weight: 500;
  line-height: 28px;
  letter-spacing: 0em;
  text-align: center;
}
.new-plans .plan2-btn {
  width: 100%;
  float: left;
}
.new-plans .plan2-btn button.btn {
  border: 1px solid var(--secondary);
  border-radius: 44px;
  color: var(--secondary);
  margin: 40px auto;
}
.new-plans .plan2-btn button.btn a {
  color: var(--secondary);
  font-family: "Roboto";
  font-size: 18px;
  font-weight: 500;
  line-height: 28px;
  letter-spacing: 0em;
  text-align: center;
}
.new-plans .plan1-para {
  width: 100%;
  float: left;
  background-image: url(../../../public/assets/img/bgsec6.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  margin-top: -64px;
  text-align: center;
  padding: 10px 17px;
  border-radius: 0 0 7px 7px;
}
.new-plans .plan1-para p {
  font-family: Roboto;
  font-size: 17px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: center;
  color: var(--secondary);
  margin-bottom: 0;
  font-style: normal;
}
.new-plans .seller-area {
  width: 22%;
  float: right;
  margin-top: -188px;
  margin-right: -16px;
}
.new-plans .seller-area img {
  margin: 0 auto;
  display: block;
}
.new-plans .seller-area p {
  font-family: Roboto;
  font-size: 16px;
  font-weight: 700;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: center;
}
.new-plans .plan-bg2 {
  box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.1607843137);
  border-radius: 7px;
  padding: 74px 30px;
  height: 753px;
  background-image: url(../../../public/assets/img/plan2_1.png);
  background-position: right;
  background-repeat: no-repeat;
  background-size: cover;
}
.new-plans .plan-bg3 {
  box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.1607843137);
  border-radius: 7px;
  padding: 74px 30px;
  height: 753px;
  background-image: url(../../../public/assets/img/plan3_1.png);
  background-position: right;
  background-repeat: no-repeat;
  background-size: cover;
}
.new-plans .m-t {
  margin-top: 20px;
  padding: 36px 30px;
  height: 735px;
}
.new-plans .new {
  margin-top: 21px !important;
}
.new-plans .m-t-n {
  margin-top: -157px;
}
.new-plans .compare-plan-area {
  width: 100%;
  float: left;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 20px 0 80px;
}
.new-plans .compare-plan-inner {
  width: 100%;
  float: left;
}
.new-plans .text-roboto {
  font-family: Roboto;
  font-style: normal;
}
.new-plans .compare-plan-inner-mobile h3 {
  font-family: Roboto;
  font-size: 8px;
  font-weight: 700;
  font-style: normal;
  line-height: 4px;
  white-space: nowrap;
  margin-bottom: 0 !important;
}
.new-plans .compare-plan-inner-mobile button {
  background: #f25d0f;
  color: #fff;
  text-align: center;
  font-family: Roboto;
  font-size: 8px;
  font-style: normal;
  font-weight: 700;
  line-height: 8.15px;
  border: none;
}
.new-plans .compare-plan-inner-mobile .compare-plan-learnMore-link {
  color: #f25d0f;
  text-align: center;
  font-family: Roboto;
  font-size: 8px !important;
  font-style: normal;
  font-weight: 600;
  line-height: 3.076px;
}
.new-plans .compare-plan-inner-desktop h3 {
  color: #000;
  font-family: Roboto;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 14px;
  margin-bottom: 0 !important;
}
.new-plans .compare-plan-inner-desktop button {
  background: #f25d0f;
  color: #fff;
  text-align: center;
  font-family: Roboto;
  font-size: 21.696px;
  font-style: normal;
  font-weight: 700;
  line-height: 34.07px;
  border: none;
}
.new-plans .compare-plan-inner-desktop .compare-plan-learnMore-link {
  color: #f25d0f;
  text-align: center;
  font-family: Roboto;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 12px;
}
.new-plans .compare-plan-area p {
  font-family: Roboto;
  font-size: 24px;
  font-weight: 500;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: center;
  margin: 0px 0 50px;
  color: #404040;
}
.new-plans .css-1yk1gt9-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root {
  color: #541cf5 !important;
  text-align: center;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
}
.new-plans .css-jedpe8-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-jedpe8-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-jedpe8-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input {
  padding-right: 25px !important;
}
.new-plans table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
}
.new-plans td,
.new-plans th {
  border: 1px solid #ffffff;
  text-align: left;
  padding: 8px;
}
.new-plans th.md {
  font-family: Roboto;
  font-size: 27px;
  font-weight: 700;
  line-height: 47px;
  letter-spacing: 0em;
  text-align: left;
  width: 40%;
  padding: 20px 30px;
  background-color: var(--secondary);
  border: none;
}
.new-plans th.care {
  font-family: Roboto;
  font-size: 22px;
  font-weight: 700;
  line-height: 47px;
  letter-spacing: 0em;
  width: 22%;
  padding: 20px 30px;
  background-color: var(--secondary);
  border: none;
  text-align: center;
}
.new-plans th.pre {
  width: 20%;
  padding: 20px 30px 28px;
  background-color: var(--secondary);
  border: none;
}
.new-plans th.plat {
  width: 20%;
  padding: 20px 30px 28px;
  background-color: var(--secondary);
  border: none;
}
.new-plans .icon-img {
  display: block;
  margin: 0 auto;
  width: 10%;
}
.new-plans th.plat h3 {
  font-family: Roboto;
  font-size: 22px;
  font-weight: 700;
  letter-spacing: 0em;
  text-align: center;
  color: var(--secondary);
  margin-top: -43px;
}
.new-plans th.pre h3 {
  font-family: Roboto;
  font-size: 22px;
  font-weight: 700;
  letter-spacing: 0em;
  text-align: center;
  color: var(--secondary);
  margin-top: -43px;
}
.new-plans td {
  border: none;
}
.new-plans td.bg-custom {
  padding: 15px 30px;
}
.new-plans .bg-white {
  background-color: #fff;
}
.new-plans .bg-wh {
  background-color: var(--secondary);
  font-family: Roboto;
  font-size: 16px;
  font-weight: 500;
  line-height: 28px;
  letter-spacing: 0em;
  text-align: left;
}
.new-plans td.pr-tbl span {
  font-family: Roboto;
  font-size: 20px;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: center;
  margin-bottom: 16px;
  width: 100%;
  float: left;
}
.new-plans .tbl-btn {
  margin: 0px auto 10px !important;
  padding: 2px 20px !important;
}
.new-plans .tbl-btn a {
  font-size: 14px !important;
}
.new-plans td.pr-tbl {
  padding: 15px 7px;
  vertical-align: middle !important;
}
.new-plans td.pr-tbl span p {
  font-size: 14px;
  color: #404040;
}
.new-plans .learn-btn {
  padding: 0 !important;
  background-color: transparent !important;
  font-family: "Roboto";
  font-size: 18px;
  font-weight: 500;
  line-height: 28px;
  letter-spacing: 0em;
  text-align: center;
  margin: 0 auto !important;
}
.new-plans .learn-btn a {
  color: var(--clrorange) !important;
  font-size: 16px;
}
.new-plans .choice-area {
  width: 100%;
  float: left;
  background-image: url(../../../public/assets/img/bgsec7.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 90px 0;
}
.new-plans .choice-inner {
  width: 100%;
  float: left;
}
.new-plans .circle-big {
  position: relative;
  width: 114px;
  height: 114px;
  margin: 30px auto 25px auto;
}
.new-plans .circle-big svg {
  width: 114px;
  height: 114px;
}
.new-plans .circle-big .bg {
  fill: none;
  stroke-width: 10px;
  stroke: rgba(26, 233, 255, 0.2901960784);
}
.new-plans .circle-big .progress {
  fill: none;
  stroke-width: 10px;
  stroke: var(--clrsky);
  stroke-linecap: round;
  stroke-dasharray: 326.56;
  stroke-dashoffset: 60;
  transform: rotate(-90deg);
  transform-origin: 50% 50%;
  animation: big 1.5s ease-in-out;
}
.new-plans .circle-big .text {
  position: absolute;
  width: 100%;
  top: 46px;
  text-align: center;
  line-height: 22px;
  font-family: Roboto;
  font-size: 31px;
  font-weight: 700;
  color: var(--secondary);
}
@keyframes big {
  from {
    stroke-dashoffset: 326.56;
  }
  to {
    stroke-dashoffset: 86;
  }
}
@keyframes one {
  from {
    stroke-dashoffset: 232.36;
  }
  to {
    stroke-dashoffset: 80;
  }
}
@keyframes two {
  from {
    stroke-dashoffset: 232.36;
  }
  to {
    stroke-dashoffset: 140;
  }
}
@keyframes appear {
  0%, 50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.new-plans p.c-para {
  font-family: "Roboto";
  font-size: 25px;
  font-weight: 400;
  line-height: 36px;
  letter-spacing: 0em;
  text-align: center;
  color: var(--secondary);
  padding: 0 50px;
}
.new-plans .trust-area {
  width: 100%;
  float: left;
  background-image: url(../../../public/assets/img/bgsec1.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 90px 0;
}
.new-plans .trust-area.pinkGradient {
  background-image: url(../../../public/assets/img/planDetails/ReviewCarouselBG.png);
}
.new-plans .trust-inner {
  width: 100%;
  float: left;
  margin-top: 60px;
}
.new-plans .trust-area span.diff-clr {
  color: var(--clrgreen);
}
.new-plans .trust1 {
  width: 100%;
  margin: 0 auto;
  display: block;
  text-align: center;
}
.new-plans .trust1 img {
  width: 20%;
}
.new-plans .trust1 h3 {
  font-family: Roboto;
  font-size: 45px;
  font-weight: 800;
  line-height: 89px;
  letter-spacing: 0.5940239429px;
  text-align: center;
  color: var(--secondary);
}
.new-plans .trust1 p {
  font-family: Roboto;
  font-size: 20px;
  font-weight: 400;
  line-height: 32px;
  letter-spacing: 0.2376095951px;
  text-align: center;
  color: var(--secondary);
}
.new-plans .trust2 {
  width: 100%;
  margin: 0 auto;
  display: block;
  text-align: center;
}
.new-plans .trust2 img {
  width: 39%;
}
.new-plans .trust2 h3 {
  font-family: Roboto;
  font-size: 45px;
  font-weight: 800;
  line-height: 89px;
  letter-spacing: 0.5940239429px;
  text-align: center;
  color: var(--secondary);
}
.new-plans .trust2 p {
  font-family: Roboto;
  font-size: 20px;
  font-weight: 400;
  line-height: 32px;
  letter-spacing: 0.2376095951px;
  text-align: center;
  color: var(--secondary);
}
.new-plans .trust1 hr {
  border: 1px solid #fff;
  width: 35%;
  margin: 0 auto;
  margin-top: 25px;
}
.new-plans .reason-area {
  width: 100%;
  float: left;
}
.new-plans .reason-inner {
  width: 100%;
  float: left;
  background-image: url(../../../public/assets/img/bgsec4.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 0px 0 0 45px;
  border-radius: 7px;
  margin-top: 90px;
}
.new-plans .reason-area.yellowGradient .reason-inner {
  background-image: url(../../../public/assets/img/yellowGradient.png);
  margin-bottom: 90px;
}
.new-plans .reason-inner h4 {
  font-family: "Roboto";
  font-size: 32px;
  font-weight: 600;
  line-height: 43px;
  letter-spacing: 0em;
  text-align: left;
  color: var(--secondary);
}
.new-plans .reason-area.yellowGradient .reason-inner h4 {
  color: var(--primary);
}
.new-plans .reason-inner img {
  width: 125%;
}
.new-plans .Subscribe-btn img {
  width: 13%;
  margin-right: 15px;
}
.new-plans .reason-btn {
  padding: 10px 13px !important;
}
.new-plans .partner-area {
  width: 100%;
  float: left;
  margin: 90px 0;
}
.new-plans .partner-inner {
  width: 100%;
  float: left;
}
.new-plans .partner-inner img {
  width: 90%;
  margin: 0 auto;
  display: block;
}
.new-plans .client-area {
  width: 100%;
  float: left;
  background-image: url(../../../public/assets/img/bgsec2.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 90px 0;
}
.new-plans .client-inner {
  width: 100%;
  float: left;
}
.new-plans .client-up {
  width: 100%;
  float: left;
  margin-bottom: 50px;
  margin-top: 35px;
}
.new-plans .client-up img {
  width: 90%;
  margin: 0 auto;
  display: block;
}
.new-plans .client-down {
  width: 100%;
  float: left;
  margin-bottom: 50px;
}
.new-plans .client-down img {
  width: 90%;
  margin: 0 auto;
  display: block;
}
.new-plans .best-area {
  width: 100%;
  float: left;
  background-image: url(../../../public/assets/img/bgsec3.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 90px 0;
}
.new-plans .best-inner {
  width: 100%;
  float: left;
}
.new-plans .best-inner span.diff-clr {
  color: var(--clrgreen);
}
.new-plans .best-inner img {
  width: 24%;
  margin: 55px auto 30px;
  display: block;
}
.new-plans .best-inner ul {
  width: 100%;
  float: left;
}
.new-plans .best-inner ul li {
  list-style: none;
  font-family: Roboto;
  font-size: 28px;
  font-weight: 500;
  line-height: 59px;
  letter-spacing: 0em;
  text-align: left;
  color: var(--secondary);
  margin-bottom: 25px;
}
.new-plans .best-inner ul li img {
  width: 3%;
  float: left;
  margin: 14px;
}
.new-plans button.btn.center-btn {
  background-color: var(--clrorange);
  padding: 13px 50px;
  border-radius: 44px;
  color: var(--secondary);
  margin: 40px auto;
  display: block;
}
.new-plans .review-area {
  width: 100%;
  float: left;
  background-image: url(../../../public/assets/img/bgsec4.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 90px 0;
}
.new-plans .review-inner {
  width: 100%;
  float: left;
}
.new-plans .review-inner img {
  width: 100%;
  float: left;
}
.new-plans img.review-bg {
  width: 50%;
  margin-top: -36%;
}
.new-plans h2.heading.text-lf.cl-wh img {
  width: 7%;
  float: right;
  margin-right: -20px;
}
.new-plans img.google-img {
  width: 27%;
  float: left;
}
.new-plans img.rat-img {
  width: 100%;
  float: left;
  margin-top: 50px;
}
.new-plans img.more-img {
  width: 75%;
  float: right;
}
.new-plans .team-area {
  width: 100%;
  float: left;
  padding: 40px 0;
}
.new-plans .our-team {
  position: relative;
  overflow: hidden;
  margin-top: 35px;
  width: 25%;
  float: left;
}
.new-plans .our-team img {
  width: 100%;
}
.new-plans .our-team .team-content {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  padding: 45px 18px;
  background: rgba(0, 0, 0, 0.7);
  transform: translateX(-100%);
  transition: all 0.2s ease 0s;
  text-align: center;
}
.new-plans .our-team:hover .team-content {
  transform: translateX(0);
}
.new-plans .our-team .team-content .post-title {
  font-family: "Roboto";
  font-size: 32px;
  font-weight: 700;
  line-height: 48px;
  letter-spacing: -0.02em;
  color: #f5f7c6;
  text-align: center;
}
.new-plans .our-team .team-content .post {
  font-size: 14px;
  color: #cb95e1;
  display: block;
  margin-bottom: 20px;
}
.new-plans .our-team .description {
  font-family: lato;
  font-size: 17px;
  font-weight: 400;
  line-height: 31px;
  letter-spacing: 1.0562499762px;
  text-align: center;
  color: var(--secondary);
}
.new-plans .our-team .team_social {
  margin: 0;
  padding: 0;
  list-style: none;
}
.new-plans .our-team .team_social li {
  display: inline-block;
  margin-right: 5px;
}
.new-plans .our-team .team_social li a {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 2px solid #f5f5f5;
  font-size: 17px;
  color: #f5f5f5;
  line-height: 40px;
  text-align: center;
  display: inline-block;
  transition: border 0.3s ease 0s;
}
.new-plans .our-team .team_social li a:hover {
  border-color: transparent;
}
.new-plans .our-team .team-prof {
  width: 100%;
  position: absolute;
  bottom: 0;
  text-align: center;
  padding: 20px 16px;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0), rgba(0, 0, 0, 0.8196078431));
  transform: translateX(0);
  transition: all 0.2s ease 0s;
}
.new-plans .our-team:hover .team-prof {
  transform: translateX(100%);
}
.new-plans .our-team .team-prof .post-title {
  font-family: "Roboto";
  font-size: 32px;
  font-weight: 700;
  line-height: 48px;
  letter-spacing: -0.02em;
  text-align: center;
  color: var(--secondary);
}
.new-plans .our-team .team-prof .post-title img {
  width: 30px;
  margin-left: 15px;
}
.new-plans .our-team .team-prof .post {
  font-family: Lato;
  font-size: 19px;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: center;
  color: #9fd68b;
}
.new-plans .our-team .team-prof .post img {
  width: 25px;
  margin-right: 15px;
}
@media only screen and (max-width: 990px) {
  .new-plans .our-team {
    width: 100%;
    float: left;
    margin-top: 0;
  }
}
.new-plans .faq-area {
  width: 100%;
  float: left;
  padding: 40px 0;
}
.new-plans .faq-inner {
  width: 100%;
  float: left;
}
.new-plans .faq-inner p {
  /* font-family: Roboto; */
  /* font-size: 30px; */
  /* font-weight: 500; */
  /* line-height: 58px; */
  letter-spacing: 0em;
  /* text-align: center; */
  /* padding: 50px 0; */
}
.new-plans .faq-img {
  width: 100%;
  float: left;
}
.new-plans .faq-img img {
  width: 80%;
  float: right;
}
.new-plans .accordion {
  cursor: pointer;
  padding: 15px 70px 15px 0;
  width: 100%;
  border-bottom: 1px solid #bbbbbb;
  outline: none;
  font-family: Roboto;
  font-size: 19px;
  font-weight: 500;
  line-height: 31px;
  letter-spacing: 1px;
  text-align: left;
}
.new-plans .accordion:after {
  content: "➕"; /* Unicode character for "plus" sign (+) */
  font-size: 14px;
  color: #777;
  float: right;
  margin-right: -70px;
  margin-top: -9px;
}
.new-plans .accordion.active:after {
  content: "➖"; /* Unicode character for "minus" sign (-) */
}
.new-plans .panel {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.2s ease-out;
}
.new-plans .panel p {
  font-family: "Roboto";
  font-size: 16px;
  font-weight: 400;
  line-height: 32px;
  letter-spacing: 0px;
  text-align: left;
  padding: 20px 0;
  border-bottom: 1px solid #bbbbbb;
  margin: 0;
}
.new-plans .panel ul {
  margin: 40px 0;
}
.new-plans .panel ul > li {
  font-size: 1.4rem;
}
.new-plans .testimonials {
  width: 100%;
  float: left;
  background-image: url(../../../public/assets/img/bgsec1.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 90px 0;
}
.new-plans .testi {
  text-align: center !important;
}
.new-plans .testi img {
  width: 3% !important;
  margin-top: 20px !important;
  float: left !important;
  position: absolute;
  margin-left: 22px;
}
.new-plans .shadow-effect {
  background: #fff;
  padding: 30px;
  border-radius: 15px;
  text-align: center;
  border: 1px solid #ececec;
  box-shadow: 0 19px 38px rgba(0, 0, 0, 0.1), 0 15px 12px rgba(0, 0, 0, 0.02);
}
.new-plans #customers-testimonials .shadow-effect p {
  font-family: "Lato";
  font-size: 19px;
  line-height: 28px;
  margin: 0 0 17px 0;
  font-weight: 500;
}
.new-plans .testimonial-name {
  margin: -17px auto 0;
  display: table;
  width: auto;
  background: #3190e7;
  padding: 9px 35px;
  border-radius: 12px;
  text-align: center;
  color: #fff;
  box-shadow: 0 9px 18px rgba(0, 0, 0, 0.12), 0 5px 7px rgba(0, 0, 0, 0.05);
}
.new-plans #customers-testimonials .item {
  text-align: center;
  padding: 0;
  margin-bottom: 80px;
  opacity: 1;
  transform: scale3d(0.8, 0.8, 1);
  transition: all 0.3s ease-in-out;
}
.new-plans #customers-testimonials .owl-item.active.center .item {
  opacity: 1;
  transform: scale3d(1, 1, 1);
}
.new-plans .owl-carousel .owl-item img {
  transform-style: preserve-3d;
  max-width: 90px;
  margin-bottom: 30px;
}
.new-plans #customers-testimonials.owl-carousel .owl-dots .owl-dot.active span,
.new-plans #customers-testimonials.owl-carousel .owl-dots .owl-dot:hover span {
  background: #fff;
  transform: translate3d(0px, -50%, 0px) scale(0.7);
}
.new-plans #customers-testimonials.owl-carousel .owl-dots {
  display: inline-block;
  width: 100%;
  text-align: center;
}
.new-plans #customers-testimonials.owl-carousel .owl-dots .owl-dot {
  display: inline-block;
}
.new-plans #customers-testimonials.owl-carousel .owl-dots .owl-dot span {
  background: #fff;
  display: inline-block;
  height: 20px;
  margin: 0 2px 5px;
  transform: translate3d(0px, -50%, 0px) scale(0.3);
  transform-origin: 50% 50% 0;
  transition: all 250ms ease-out 0s;
  width: 20px;
}
.new-plans .owl-nav {
  text-align: center;
}
.new-plans button.owl-prev {
  font-size: 105px !important;
  position: absolute;
  left: 45%;
  bottom: -7.5%;
  color: var(--secondary) !important;
}
.new-plans button.owl-next {
  font-size: 105px !important;
  position: absolute;
  right: 45%;
  bottom: -7.9%;
  color: var(--secondary) !important;
}
.new-plans button.owl-dot.active span {
  color: var(--clrblue) !important;
  border-radius: 24px !important;
}
.new-plans button.owl-dot span {
  color: var(--secondary) !important;
  border-radius: 24px !important;
}
.new-plans .shadow-effect h3 {
  font-family: Lato;
  font-size: 31px;
  font-weight: 700;
  line-height: 33px;
  letter-spacing: 0em;
  text-align: left;
}
.new-plans .shadow-effect h6 {
  font-family: Lato;
  font-size: 16px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
}
.new-plans .shadow-effect ul {
  width: 100%;
  float: left;
}
.new-plans .shadow-effect ul li {
  list-style: none;
  display: inline-block;
  margin-top: 34px;
}
.new-plans .shadow-effect ul li img {
  width: 24px !important;
}
.new-plans .shadow-effect h5 {
  font-family: Lato;
  font-size: 27px;
  font-weight: 700;
  line-height: 38px;
  letter-spacing: 0em;
  text-align: center;
  margin-bottom: 14px;
}
.new-plans .footer-area {
  width: 100%;
  float: left;
  background-color: var(--ftbg);
  padding: 60px 0;
}
.new-plans .footer-inner {
  width: 100%;
  float: left;
}
.new-plans .foot-1 {
  width: 100%;
  float: left;
}
.new-plans .foot-1 img {
  width: 100%;
  float: left;
  margin-bottom: 40px;
}
.new-plans .foot-about {
  width: 100%;
  float: left;
}
.new-plans .foot-about p {
  font-family: "Roboto";
  font-size: 16px;
  font-weight: 600;
  line-height: 21px;
  letter-spacing: 0px;
  text-align: left;
}
.new-plans .foot-about ul {
  width: 100%;
  float: left;
}
.new-plans .foot-about ul li {
  display: block;
  margin-bottom: 8px;
}
.new-plans .foot-2 {
  width: 100%;
  float: left;
}
.new-plans ul.dis-dif {
  width: 100%;
  float: left;
}
.new-plans ul.dis-dif li {
  display: inline-block;
}
.new-plans ul.dis-dif img {
  width: 33px;
  margin-bottom: 0;
  padding: 7px;
}
.new-plans .foot-about ul li a {
  font-family: "Roboto";
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0px;
  text-align: left;
  color: #7d7d7d;
}
.new-plans .foot-3 {
  width: 100%;
  float: left;
}
.new-plans .foot-3 img {
  width: 100%;
  height: 75px;
}
.new-plans input[type=email] {
  display: inline-block;
  width: 99%;
  padding: 0px 17px;
  margin: 8px 0;
  border: 1px solid #ccc;
  box-sizing: border-box;
  height: 43px;
  margin-top: 18px;
  border-radius: 20px;
  border: none;
  box-shadow: 0 20px 30px 0 rgba(0, 0, 0, 0.06);
  font-family: "Lato";
  font-size: 13px;
}
.new-plans input,
.new-plans input::-webkit-input-placeholder {
  font-size: 16px;
  line-height: 3;
}
.new-plans .ft-sb-bt {
  margin-left: -94px;
  font-size: 17px;
  display: inline-block;
  font-weight: 600;
  padding: 10px 10px;
  border-radius: 0px 20px 20px 0px;
  border: none;
  cursor: pointer;
  background-color: var(--clrblue);
  color: #fff;
  font-family: "Lato";
}
.new-plans .copy-right-area {
  width: 100%;
  float: left;
  padding: 20px 0 0;
}
.new-plans .copy-right-area p {
  font-family: "Roboto";
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0px;
  text-align: center;
  color: #541cf5;
}
@media screen and (max-device-width: 767px) {
  .new-plans {
    /* ********* Nav-Area CSS************** */
    /* ul.navbar-nav.me-auto.my-2.my-lg-0.navbar-nav-scroll {
            overflow: initial;
            background-color: var(--secondary);
            z-index: 9999;
            display: block;
            max-height: 30vh;
            padding: 0 16px;
        }
         */
    /* ********* Hero-Area CSS ************** */
    /* ********* Personal-Data-Area CSS************** */
    /* ********* Pick-Plan-Area CSS************** */
    /* ********* Make-Us-Area CSS************** */
    /* ********* Compare-Plan-Area CSS************** */
    /* ********* trust-area CSS************** */
    /* ********* Reason-Area CSS************** */
    /* ********* Partner-Area CSS************** */
    /* ********* Client-Area CSS************** */
    /* ********* Best-Area CSS************** */
    /* ********* Review-Area CSS************** */
    /* ********* Team-Area CSS************** */
    /* ********* FAQ-Area CSS************** */
    /* ********* Testimonial-Area CSS************** */
    /* .owl-nav {
        margin-top: -131px;
    } */
    /* ********* Footer-Area CSS************** */
    /* -----Whatsapp-sticky------- */
  }
  .new-plans .mobile-display-none {
    display: none;
  }
  .new-plans .mobile-display {
    display: block;
  }
  .new-plans span.diff-clr-m {
    color: #ef4e4e;
  }
  .new-plans a.navbar-brand img {
    width: 150px;
  }
  .new-plans ul.navbar-nav.me-auto.my-2.my-lg-0.navbar-nav-scroll {
    overflow: initial;
    background-color: var(--secondary);
    z-index: 9999;
    display: block;
    max-height: 50vh;
    padding: 0 16px;
  }
  .new-plans .search {
    width: 35px;
    height: 33px;
    transform: 0.5s;
    background: #fff;
    overflow: hidden;
    position: relative;
    border-radius: 60px;
    box-shadow: 0 0 0 0 5px #7532d4;
    border: 1px solid var(--clrblue);
    left: 66px;
  }
  .new-plans .search.active {
    width: 190px;
    border: 1px solid var(--clrblue);
    left: 0;
  }
  .new-plans .search .icon {
    top: 0;
    left: 1px;
    width: 35px;
    height: 35px;
    display: flex;
    z-index: 1000;
    cursor: pointer;
    position: absolute;
    background: #fff;
    border-radius: 60px;
    align-items: center;
    justify-content: center;
  }
  .new-plans .search .icon:before {
    content: "";
    width: 15px;
    height: 15px;
    position: absolute;
    border-radius: 50%;
    border: 3px solid #8338ec;
    transform: translate(-4px, -4px);
  }
  .new-plans .search .icon:after {
    content: "";
    width: 2px;
    height: 8px;
    position: absolute;
    background: #8338ec;
    transform: translate(4px, 5px) rotate(315deg);
  }
  .new-plans .search .input {
    left: 9px;
    width: 353px;
    height: 33px;
    display: flex;
    position: relative;
    align-items: center;
    justify-content: center;
    font-family: "Roboto";
  }
  .new-plans .search .input input {
    top: 0;
    width: 85%;
    height: 95%;
    border: none;
    outline: none;
    font-size: 13px;
    padding: 10px 0;
    position: absolute;
  }
  .new-plans .clear {
    top: 20%;
    right: 15px;
    width: 15px;
    height: 15px;
    display: flex;
    cursor: pointer;
    background: #ff0;
    position: relative;
    align-items: center;
    justify-content: center;
    transform: translateY(-50%);
  }
  .new-plans .clear:before {
    width: 1px;
    height: 15px;
    content: "";
    position: absolute;
    background: #999;
    transform: rotate(45deg);
  }
  .new-plans .clear:after {
    width: 1px;
    height: 15px;
    content: "";
    position: absolute;
    background: #999;
    transform: rotate(315deg);
  }
  .new-plans button.navbar-toggler.collapsed {
    border: none;
    padding: 0;
    color: var(--primary);
  }
  .new-plans button.navbar-toggler {
    border: none;
    padding: 0;
    color: var(--primary);
  }
  .new-plans li.nav-item {
    border-bottom: 1px solid #efefef;
  }
  .new-plans .hero-text {
    width: 100%;
    float: left;
    padding-right: 0;
    padding-top: 30px;
  }
  .new-plans .hero-area {
    padding: 40px 0;
    height: 1050px;
  }
  .new-plans .hero-text h1 {
    font-size: 22px;
    line-height: 0;
  }
  .new-plans .hero-text h4 {
    font-size: 16px;
    line-height: 50px;
    text-align: center;
    margin-bottom: 0;
  }
  .new-plans .Subscribe-btn button.btn {
    padding: 6px 25px;
    margin: 0 auto;
    display: block;
  }
  .new-plans .Subscribe-btn button.btn a {
    font-size: 13px;
  }
  .new-plans .btm-text {
    font-size: 12px;
    line-height: 54px;
    text-align: center;
  }
  .new-plans .img1 {
    width: 78%;
    margin-left: 0;
  }
  .new-plans .img1 img {
    width: 85%;
    margin-top: 20px;
  }
  .new-plans .img2 {
    width: 61%;
    position: absolute;
    right: 3.9%;
  }
  .new-plans .img3 img {
    margin-top: 10px;
    margin-left: 28px;
  }
  .new-plans .img5 img {
    margin-top: 2px;
  }
  .new-plans .img4 img {
    margin-left: 13px;
  }
  .new-plans .hero-img {
    margin-bottom: 40px;
  }
  .new-plans .icon-bg {
    width: 19%;
    padding: 12px;
    margin: 0 auto;
  }
  .new-plans .icon-area h5 {
    font-size: 16px;
    line-height: 21px;
    text-align: center;
    margin-top: 20px;
  }
  .new-plans .icon-area p {
    font-size: 12px;
    line-height: 16px;
    text-align: center;
    padding: 0 77px;
  }
  .new-plans .personal-data-area {
    margin-top: 0;
  }
  .new-plans .personal-data-inner {
    padding: 10px 0px 10px 0px;
    box-shadow: none;
  }
  .new-plans .personal-data-text h3 {
    font-size: 23px;
    line-height: 27px;
    text-align: center;
    margin-top: 20px;
  }
  .new-plans .personal-data-img ul li {
    display: block;
  }
  .new-plans .personal-data-img ul li img {
    width: 29.33%;
    float: left;
    box-shadow: 1px 1px 8px 2px #ddd;
    margin: 0 2%;
    height: 82px;
  }
  .new-plans .personal-data-img {
    margin-top: 25px;
  }
  .new-plans .pick-plan-area {
    margin: 40px 0;
  }
  .new-plans h2.heading {
    font-size: 30px;
    line-height: 40px;
  }
  .new-plans .plan ul li h5 {
    font-size: 15px;
  }
  .new-plans .plan ul li p {
    font-size: 13px;
    line-height: 19px;
  }
  .new-plans .plan-btn button.btn {
    padding: 5px 35px;
    margin: 40px auto 0;
  }
  .new-plans .plan-btn button.btn a {
    font-size: 14px;
    line-height: 8px;
  }
  .new-plans .plan-bg {
    height: 600px;
    margin: 20px 0;
  }
  .new-plans .plan-bg1 {
    margin: 0px 0px;
    height: 675px;
  }
  .new-plans .plan1 h3 {
    font-size: 22px;
    line-height: 30px;
    letter-spacing: 1px;
  }
  .new-plans .plan1 h6 {
    font-size: 10px;
    line-height: 10px;
    margin-bottom: 17px;
  }
  .new-plans .seller-area {
    width: 37%;
    margin-top: -196px;
    margin-right: 0;
    float: left;
  }
  .new-plans .seller-area p {
    font-size: 12px;
  }
  .new-plans .plan1-btn button.btn {
    /* padding: 4px 10px; */
    margin: 17px auto;
  }
  .new-plans .plan1-btn button.btn a {
    font-size: 15px;
    line-height: 7px;
  }
  .new-plans .plan2-btn button.btn {
    /* padding: 4px 19px; */
    margin: 17px auto;
  }
  .new-plans .plan2-btn button.btn a {
    font-size: 15px;
    line-height: 7px;
  }
  .new-plans .plan1-para {
    margin-top: -52px;
  }
  .new-plans .plan1-para p {
    font-size: 13px;
    line-height: 16px;
  }
  .new-plans .plan1 ul li h5 {
    font-size: 15px;
  }
  .new-plans .plan1 ul li p {
    font-size: 13px;
    line-height: 19px;
  }
  .new-plans .m-t {
    height: 760px;
  }
  .new-plans .m-t-n {
    margin-top: -187px !important;
  }
  .new-plans .make-us-area {
    width: 100%;
    float: left;
    margin-bottom: 40px;
  }
  .new-plans .make-us-inner {
    width: 100%;
    float: left;
  }
  .new-plans .make-us-inner img {
    width: 100%;
    float: left;
  }
  .new-plans .compare-plan-area {
    padding: 20px 0;
  }
  .new-plans table {
    width: 100%;
    display: block;
    overflow-x: auto;
  }
  .new-plans th.md {
    font-size: 15px;
    padding: 0px 0px 0 20px;
    line-height: 28px;
  }
  .new-plans th.care {
    font-size: 14px;
    padding: 0px 20px;
  }
  .new-plans th.pre {
    padding: 0px 20px;
  }
  .new-plans th.pre img {
    width: 100%;
  }
  .new-plans th.pre h3 {
    font-size: 10px;
    margin-top: -16px;
  }
  .new-plans th.plat {
    padding: 0px 20px;
  }
  .new-plans th.plat img {
    width: 100%;
  }
  .new-plans th.plat h3 {
    font-size: 10px;
    margin-top: -16px;
  }
  .new-plans td.bg-custom {
    padding: 3px 20px;
  }
  .new-plans .m-table-size {
    font-size: 11px;
    line-height: 18px;
  }
  .new-plans .m-para-size {
    font-size: 9px;
    line-height: 12px;
    padding: 0;
  }
  .new-plans .icon-img {
    width: 20%;
  }
  .new-plans td.pr-tbl span {
    font-size: 13px;
    margin-bottom: 6px;
    white-space: nowrap;
  }
  .new-plans td.pr-tbl .tbl-btn {
    padding: 0px 7px 4px !important;
  }
  .new-plans td.pr-tbl .tbl-btn a {
    font-size: 10px !important;
    line-height: 0 !important;
  }
  .new-plans td.pr-tbl .learn-btn {
    padding: 0 !important;
  }
  .new-plans td.pr-tbl .learn-btn a {
    font-size: 10px !important;
    line-height: 0 !important;
  }
  .new-plans .trust-area {
    padding: 40px 0;
  }
  .new-plans h2.heading1 {
    font-size: 26px;
    line-height: normal;
    margin-bottom: 14px;
  }
  .new-plans .trust-inner {
    margin-top: 20px;
  }
  .new-plans .trust1 img {
    width: 15%;
  }
  .new-plans .trust1 h3 {
    font-size: 32px;
    line-height: 57px;
  }
  .new-plans .trust1 p {
    font-size: 16px;
    line-height: 21px;
    margin-bottom: 50px;
  }
  .new-plans .trust2 img {
    width: 15%;
  }
  .new-plans .trust2 h3 {
    font-size: 32px;
    line-height: 57px;
  }
  .new-plans .trust2 p {
    font-size: 16px;
    line-height: 0px;
    margin-bottom: 50px;
  }
  .new-plans .reason-area {
    width: 100%;
    float: left;
    background-image: url(../../../public/assets/img/bgsec4.jpg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    margin-top: 0;
    padding: 40px 0;
  }
  .new-plans .reason-inner {
    width: 100%;
    float: left;
    margin-top: 0;
    padding: 0;
    background: none;
  }
  .new-plans .reason-inner h4 {
    font-size: 20px;
    line-height: 32px;
    text-align: center;
    margin-top: 0;
  }
  .new-plans .reason-btn {
    padding: 3px 20px !important;
    margin-top: 0px !important;
    background-color: var(--clrorange) !important;
  }
  .new-plans .partner-area {
    margin: 40px 0;
  }
  .new-plans .text-lf {
    text-align: center !important;
  }
  .new-plans .client-area {
    padding: 40px 0;
  }
  .new-plans .client-up {
    margin-bottom: 10px;
    margin-top: 0;
  }
  .new-plans .m-client {
    width: 25%;
    float: left;
  }
  .new-plans .client-down {
    width: 100%;
    float: left;
    margin-bottom: 0;
  }
  .new-plans .best-area {
    padding: 40px 0;
  }
  .new-plans .best-inner img {
    width: 30%;
    margin: 15px auto 20px;
  }
  .new-plans .best-inner ul li {
    font-size: 12px;
    line-height: 21px;
    margin-bottom: 10px;
  }
  .new-plans .best-inner ul li img {
    width: 6%;
    margin: 4px 14px 0 0;
  }
  .new-plans .center-btn {
    padding: 2px 25px;
    margin: 0 auto !important;
  }
  .new-plans .review-area {
    padding: 40px 0;
  }
  .new-plans img.google-img {
    width: 50%;
    display: block;
    margin: 0 auto;
    float: none;
    padding-bottom: 20px;
  }
  .new-plans img.more-img {
    width: 75%;
    float: none;
    display: block;
    margin: 0 auto;
    padding-bottom: 20px;
  }
  .new-plans h2.heading.text-lf.cl-wh img {
    width: 10%;
    margin-top: 5px;
    margin-right: -9px;
  }
  .new-plans img.rat-img {
    margin-top: 15px;
  }
  .new-plans .team-area {
    padding: 40px 0;
  }
  .new-plans h2.heading.m-dis {
    font-size: 24px;
    line-height: 30px;
  }
  .new-plans .our-team .team-prof .post-title {
    font-size: 25px;
  }
  .new-plans .our-team .team-prof .post-title img {
    width: 20px;
    margin-left: 15px;
  }
  .new-plans .our-team .team-prof .post {
    font-size: 14px;
  }
  .new-plans .our-team .team-prof .post img {
    width: 16px;
    margin-right: 9px;
  }
  .new-plans .our-team .team-content .post-title {
    font-size: 20px;
  }
  .new-plans .our-team .description {
    font-size: 19px;
    line-height: 30px;
  }
  .new-plans .faq-area {
    padding: 40px 0;
  }
  .new-plans .faq-inner p {
    font-size: 14px;
    line-height: 11px;
    /* margin: 20px 0; */
  }
  .new-plans .faq-area .plan1-btn button.btn a {
    font-size: 16px !important;
    line-height: 0px;
  }
  .new-plans .faq-area .plan1-btn button.btn {
    padding: 10px 40px 5px !important;
  }
  .new-plans .faq-img {
    margin: 40px 0;
  }
  .new-plans .faq-img img {
    width: 90%;
    float: none;
    margin: 0 auto;
    display: block;
  }
  .new-plans .accordion {
    padding: 15px 23px 15px 0;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0px;
  }
  .new-plans .accordion:after {
    font-size: 10px;
    margin-right: -22px;
    margin-top: -4px;
  }
  .new-plans .panel p {
    font-size: 11px !important;
    line-height: 20px;
    margin: 0;
  }
  .new-plans .testimonials {
    padding: 40px 0;
  }
  .new-plans .testi img {
    width: 8% !important;
    margin-top: 9px !important;
    margin-left: 5px;
  }
  .new-plans #customers-testimonials .item {
    margin-top: -12px;
    margin-bottom: 0px;
  }
  .new-plans .shadow-effect {
    padding: 30px 15px;
  }
  .new-plans .shadow-effect h3 {
    font-size: 26px;
    line-height: 19px;
  }
  .new-plans .shadow-effect h6 {
    font-size: 10px;
    line-height: 16px;
  }
  .new-plans .shadow-effect ul li img {
    width: 15px !important;
    margin-bottom: 0 !important;
  }
  .new-plans .shadow-effect ul li {
    margin-top: 23px;
  }
  .new-plans .shadow-effect img {
    margin-bottom: 0;
  }
  .new-plans .shadow-effect h5 {
    font-size: 19px;
  }
  .new-plans #customers-testimonials .shadow-effect p {
    font-size: 14px;
    line-height: 20px;
  }
  .new-plans button.owl-prev {
    left: 15%;
    bottom: -9.5%;
  }
  .new-plans button.owl-next {
    right: 15%;
    bottom: -9.9%;
  }
  .new-plans .footer-area {
    padding: 40px 20px;
  }
  .new-plans .foot-1 img {
    margin-bottom: 20px;
  }
  .new-plans .foot-about p {
    margin-bottom: 5px;
    font-size: 14px;
  }
  .new-plans img.m-img {
    width: 60%;
  }
  .new-plans .foot-about ul li {
    margin-bottom: 3px;
  }
  .new-plans .foot-about ul {
    margin-bottom: 12px;
  }
  .new-plans input[type=email] {
    width: 98%;
    height: 38px;
    margin-top: 7px;
    margin-bottom: 20px;
  }
  .new-plans .ft-sb-bt {
    margin-left: -86px;
    font-size: 13px;
    padding: 10px 14px;
  }
  .new-plans .foot-3 img {
    height: 60px;
    margin-top: 14px;
  }
  .new-plans .whats-app img {
    text-align: right;
    float: right;
    margin-top: -30px;
    width: 24%;
  }
}/*# sourceMappingURL=Plans.css.map */