.complete-quiz {
  display: flex;
  overflow-x: hidden;
}

.complete-quiz-section {
  height: 70rem;
}

.complete-quiz-img {
  width: 6.375rem;
  height: 6.375rem;
  margin-left: 40%;
}

.complete-quiz-question-container {
  width: 55rem;
  height: max-content;
  border-radius: 0.72588rem;
  background: #f4f0ff;
  margin-left: 8rem;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.green-tick {
  width: 1.25rem;
  height: 1.25rem;
  margin-top: 0.35rem;
}

.complete-items {
  display: flex;
  width: 51.625rem;
  height: 2rem;
  gap: 0.5rem;
}

.complete-items-text {
  color: #252625;
  font-family: Roboto;
  font-size: 1.2rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.93563rem; /* 140.771% */
}

.complete-quiz-btn {
  display: flex;
  /* justify-content: space-between; */
  gap: 50rem;
  margin-top: 2.5rem;
  cursor: pointer;
}

@media (max-width: 425px) {
  .complete-quiz-section {
    height: 50rem;
  }
  .complete-quiz-sidebar {
    display: none;
  }
  .complete-quiz-img {
    width: 3.1875rem;
    height: 3.1875rem;
    margin-left: 40%;
  }
  .complete-quiz-question-container {
    width: 22rem;
    height: 22rem;
    max-height: max-content;
    margin-left: 0;
    padding: 1rem;
    gap: 2rem;
  }
  .complete-items {
    display: flex;
    /* height: 2rem; */
    height: 0rem;
    margin-bottom: 2rem;
    width: 100%;
    gap: 0.5rem;
  }
  .complete-items-text {
    font-size: 0.875rem;
    line-height: 1.2rem; /* 140.771% */
  }
  .green-tick {
    width: 1.25rem;
    height: 1.25rem;
    margin-top: 0.2rem;
  }
  .complete-quiz-btn {
    gap: 3rem;
  }
}
