.hero-section {
  width: 100%;
  height: 75vh;
  min-height: 60vh;
  align-items: center;
  position: relative;
  display: flex;
  flex-direction: row;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.hero-main {
  /* margin-left: 15rem; */
  margin-top: -5rem;
  display: flex;
  flex-direction: column;
}

.video {
  min-width: 80%;
  width: 100%;
  height: 50rem;
  margin-left: -28rem;
}

.bg-image {
  /* height: 20rem;
  width: 10rem; */
  /* height: 38.6875rem; */
  flex-shrink: 0;
  padding: 0;
  margin: 0;
  animation: fadeIn 3s ease-in-out forwards;
  position: absolute;
  top: 0;
  right: 0;
  z-index: -1;
}

.hero-section > div {
  max-width: 1080px;
  width: 100%;
}

.hero-content {
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 2rem;
}

.hero-left {
  width: 70%;
  min-height: 21rem;
  animation: slideUp 2s ease-in-out forwards;
}

@keyframes slideUp {
  0% {
    transform: translateY(20%);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

.hero-blueHeading {
  margin-top: 5rem;
  font-family: roboto;
  font-weight: 700;
  font-size: 1.375rem;
  color: #4b378e;
  line-height: 140%;
  width: 100%;
}

.hero-heading {
  font-family: roboto;
  width: 80%;
  font-weight: 700;
  font-size: 3rem;
  color: #1f1f1f;
  line-height: 140%;
}

.hero-selfPaced {
  font-family: inter;
  font-weight: 500;
  font-size: 1.1rem;
  line-height: 20px;
  background-color: #f2f4f7;
  border-radius: 1rem;
  width: max-content;
  padding: 0.5rem;
  color: #4f4f4f;
}

.hero-info {
  display: flex;
  /* width: 40rem; */
  margin: 1rem 0;
}

.hero-enrollments {
  width: 283px;
  height: 30px;
  gap: 0.3rem;
  display: flex;
  flex-direction: row;
}

.hero-rating {
  display: flex;
  flex-direction: row;
  gap: 0.3rem;
  width: 74px;
  height: 31px;
}

.hero-infoText {
  font-family: inter;
  font-weight: 600;
  font-size: 1.2rem;
  line-height: 30px;
  align-items: center;
}

.hero-desc {
  width: 80%;
  /* height: 90px; */
  text-align: justify;
  font-family: inter;
  font-weight: 400;
  font-size: 1.1rem;
  line-height: 30px;
  color: #000000;
}

.hero-read-more {
  cursor: pointer;
  overflow: auto;
  font-weight: 700;
  text-decoration: underline;
}

.hero-btn {
  margin-top: 1.2rem;
  width: 16rem;
  height: 3.25rem;
  top: 34rem;
  left: 16rem;
  gap: 1.5px;
  border-radius: 54px;
  padding: 0.8rem 3.1rem;
  background-color: #fd6f1f;
  font-family: helvetica;
  font-weight: 700;
  font-size: 1.1rem;
  line-height: 1.5rem;
  color: white;
}

.hero-whiteLayout {
  width: 75rem;
  height: 6.25rem;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 52%);
  border-radius: 1rem;
  padding: 1.8rem 5.5rem;
  gap: 3.75rem;
  display: flex;
  flex-wrap: wrap;
  border: 10px white;
  background-color: white;
  animation: fadeIn 3s ease-in-out forwards;
  box-shadow: 0px 1px 5px 0px #0000001a;
}

.hero-layout-items {
  display: flex;
  /* flex-wrap: wrap; */
  align-items: center;
  gap: 0.6rem;
  font-family: inter;
  font-weight: 600;
  font-size: 1.1rem;
  position: relative;
  line-height: 1.6rem;
}

.hero-layout-items:hover {
  cursor: pointer;
}

.hero-layout-items::before {
  content: "";
  position: absolute;
  bottom: -8px;
  margin-left: 3px;
  height: 3px;
  width: 0px;
  border-radius: 50px;
  background-color: #541cf5;
  transition: width 1s ease-in-out;
}

.hero-layout-items:hover::before {
  width: 100%;
}

.hero-side-btn {
  position: relative;
}

.abc {
  cursor: pointer;
  display: flex;
  gap: 0.5rem;
  flex-direction: column;
  position: absolute;
  top: 6.25rem;
  left: -3.43rem;
  /* animation: slideUp 3s ease-in-out forwards; */
}

@media (max-width: 425px) {
  .hero-section {
    height: 115vh;
    min-height: 80vh;
    margin-top: 2rem;
  }
  .hero-blueHeading {
    position: absolute;
    /* margin-top: -22rem; */
    font-size: 1rem;
    top: -9rem;
  }
  .video {
    min-width: 50%;
    width: 50%;
    height: 40rem;
    margin-top: -30rem;
    /* margin-left: -28rem; */
  }
  .hero-main {
    position: relative;
    padding: 2rem;
    margin-left: -1rem;
    display: flex;
    flex-direction: column;
  }
  .hero-heading {
    font-size: 1.65rem;
    line-height: 2.1rem;
    width: 100%;
    min-width: 311px;
    height: 78px;
  }
  .hero-content {
    display: flex;
    flex-direction: row;
    width: 100%;
    gap: 2rem;
    margin-top: 20rem;
  }
  .hero-left {
    margin-left: -0.5rem;
    width: 70%;
    min-height: 21rem;
    animation: slideUp 2s ease-in-out forwards;
  }
  .hero-selfPaced {
    font-family: inter;
    font-weight: 500;
    font-size: 1.1rem;
    line-height: 20px;
    background-color: #f2f4f7;
    border-radius: 1rem;
    width: max-content;
    padding: 0.5rem;
    color: #4f4f4f;
  }
  .hero-info {
    width: 150%;
  }
  .hero-desc {
    width: 85vw;
    height: max-content;
    /* overflow: hidden; */
  }
  .hero-btn {
    margin-top: 1rem;
    margin-left: 3rem;
  }
  .hero-whiteLayout {
    height: 5.5rem;
    width: 25.81rem;
    bottom: -3rem;
    left: 55%;
    padding: 0.5rem;
    gap: 0.5rem;
    display: flex;
    flex: 1;
    flex-wrap: wrap;
    justify-content: space-evenly;
  }
  .hero-layout-items {
    flex: 1; /* Each item takes equal width */
    flex-basis: 48%; /* Adjust the width as needed */
    display: flex;
    align-items: center;
    /* margin-left: -0.75rem; */
    padding-left: 0.1rem;
    gap: 0.25rem;
    font-family: inter;
    font-weight: 600;
    font-size: 0.7rem;
    position: relative;
    line-height: 1rem;
  }
  /* .hero-layout-items:last-child {
    margin-left: -5rem;
  } */
  .abc {
    gap: 0.5rem;
    flex-direction: row;
    position: absolute;
    top: 5rem;
    left: 7.5rem;
  }
}
