/* mood bar */
.mood-section {
  margin-top: 2rem;
  width: 100%;
  height: 17rem;
  background-image: url("../assets/mood-monitor/mood-monitor-bg-img.png");
  /* background: linear-gradient(180deg, #541cf5 0%, rgba(84, 28, 245, 0.22) 100%); */
  border-radius: 1rem;
  /* position: relative;
  z-index: -2; */
}

.mood-container {
  width: 31rem;
  height: 11rem;
  gap: 4rem;
  margin-top: 4rem;
  position: relative;
}

.mood-heading {
  font-family: rubik;
  font-weight: 500;
  font-size: 1.9rem;
  line-height: 2.1rem;
  text-align: center;
  color: #ffffff;
  top: 4rem;
  margin-left: 19rem;
  width: 100%;
  position: absolute;
}

.mood-emojis {
  position: absolute;
  display: flex;
  gap: 0.8rem;
  margin-top: -4rem;
  margin-left: 23rem;
  width: 100%;
  cursor: pointer;
}

.mood-emoji {
  display: flex;
  flex-direction: column;
  transition: transform 1s;
}

.mood-emoji:hover {
  transform: scale(1.3);
}

.emoji {
  width: 4.25rem;
  height: 4.25rem;
}

.emoji-text {
  font-family: rubik;
  font-weight: 500;
  font-size: 1.1rem;
  line-height: 1.5rem;
  color: #ffffff;
  text-align: center;
}

.saved-text {
  margin: 3rem 0 0 32rem;
  width: 6.875rem;
  height: 2.5rem;
  padding: 0.5rem;
  border-radius: 2.25rem;
  background: rgba(255, 255, 255, 0.24);
  backdrop-filter: blur(14.5px);
  color: #fff;
  text-align: center;
  font-family: Rubik;
  font-size: 1.26594rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  animation: fade 2s ease-in-out; /* Adjust the animation duration as needed */
}

@keyframes fade {
  0%,
  100% {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }
}

/* mood monitor */

.mood-monitor-section {
  overflow-x: hidden;
  min-height: 100rem;
  height: auto;
}

.mood-monitor-name {
  width: 72.4375rem;
  flex-shrink: 0;
  color: #000;
  font-family: Roboto;
  font-size: 2.125rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1.43919rem; /* 67.727% */
  margin: 1rem 0;
  border-bottom: 0.0625rem solid #e0e0e0;
  display: flex;
  gap: 1rem;
}

.mood-monitor {
  width: 80rem;
  border-radius: 1.25rem;
  background: #fff;
  box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.12);
  padding: 5rem;
  margin: -25rem 0 2rem 3rem;
  z-index: 10;
  position: absolute;
}

.mood-analysis-heading {
  display: flex;
  justify-content: space-between;
  margin: 2rem 0;
  color: #100f11;
  font-family: Roboto;
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.mood-monitor-desc {
  color: #000;
  font-family: Roboto;
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 1.6875rem */
  letter-spacing: 0.02813rem;
}

.mood-analysis-calendar {
  display: flex;
  gap: 1rem;
  font-size: 1rem;
  text-decoration-line: underline;
}

.mood-analysis-container {
  width: 70rem;
  bottom: 0;
  height: max-content;
  border-radius: 0.4375rem;
  background: #fff;
  box-shadow: 0px 4px 24px 0px rgba(0, 0, 0, 0.1);
}

.mood-analysis-item {
  display: flex;
  justify-content: space-between;
  padding: 1.25rem 1.5rem;
  border-bottom: 0.0625rem solid #e7e7e7;
}

.mood-analysis-item:last-child {
  border-bottom: none;
}
/* .mood-line {
  width: 77.3125rem;
  height: 0.0625rem;
  background: linear-gradient(0deg, #e7e7e7 0%, #e7e7e7 100%);
} */

.mood-analysis-text {
  display: flex;
  gap: 1rem;
}

.mood-monitor-emoji {
  width: 1.86538rem;
  height: 1.86538rem;
  margin-top: -0.1rem;
}

.mood-dlt-icon,
.calendar-icon,
.info-icon {
  width: 1.5rem;
  height: 1.5rem;
  cursor: pointer;
}

.info-icon {
  margin-top: 0.5rem;
}

.mood-monitor-btn {
  display: flex;
  /* justify-content: space-between; */
  gap: 50rem;
  margin-top: 2.5rem;
  cursor: pointer;
}

.modules-prev-text {
  color: #333;
  font-family: Roboto;
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-align: right;
}

.prev-arrow,
.next-arrow {
  min-width: 2.1875rem;
  min-height: 2.1875rem;
  width: 2.1875rem;
  height: 2.1875rem;
  margin-left: -0.1rem;
  border-radius: 100%;
  margin-top: -0.5rem;
  box-shadow: 0px 2.5px 8.125px 0px #00000030;
  cursor: pointer;
}

.prev-arrow {
  transform: rotate("90");
}

.prev-btn,
.next-btn {
  display: flex;
  gap: 0.625rem;
}

.prev-btn {
  flex-direction: row-reverse;
}

@media (max-width: 425px) {
  .mood-monitor-section {
    height: 70rem;
  }
  .mood-monitor-sidebar,
  .mood-monitor-bg-img {
    display: none;
  }
  .mood-monitor {
    width: 100%;
    box-shadow: none;
    border-radius: 0;
    padding: 0.5rem;
    margin: 0;
  }
  .mood-monitor-desc {
    font-size: 0.875rem;
    letter-spacing: 0.02188rem;
  }
  .mood-analysis-calendar {
    margin-left: -1rem;
    gap: 0.5rem;
  }
  .mood-analysis-container {
    width: 98%;
    height: 20.9375rem;
    border-radius: 0.4375rem;
    background: #fff;
    box-shadow: 0px 4px 24px 0px rgba(0, 0, 0, 0.1);
  }
  .mood-monitor-btn {
    gap: 4rem;
  }
  .mood-section {
    margin-top: 2rem;
    padding: 0;
    width: 105%;
    margin-left: -1rem;
    height: 12.1875rem;
    border-radius: 0;
  }
  .mood-heading {
    font-size: 1.22rem;
    top: 2rem;
    margin-left: -10%;
    width: 100%;
    position: absolute;
  }
  .mood-emojis {
    margin-top: -6rem;
    margin-left: 15%;
  }
  .emoji {
    width: 2.71244rem;
    height: 2.71244rem;
  }
}
