.stella {
  display: flex;
  overflow-x: hidden;
}

.stella-section {
  height: 75rem;
}

.stella-name {
  display: flex;
  gap: 34rem;
}

.stella-container {
  width: 78.5rem;
  height: max-content;
  border-radius: 1.25rem;
  background: #fff;
  box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.12);
  padding: 3rem;
  position: absolute;
  z-index: 10;
  margin: -15rem 0 2rem 3.5rem;
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.white-layout {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.name {
  color: #000;
  font-family: Roboto;
  font-size: 2.125rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1.43919rem; /* 67.727% */
}
.stella-sections-progress {
  color: #6d6d6d;
  font-family: Roboto;
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.43919rem; /* 127.928% */
  letter-spacing: 0.03125rem;
}

.talk-to-stella-container {
  width: 71.9375rem;
  height: 39.875rem;
  border-radius: 1.25rem;
  border: 1px solid #cecece;
  padding: 3rem 2rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.stella-chat {
  display: flex;
  gap: 1rem;
}

.stella-img {
  width: 2.5rem;
  height: 2.5rem;
}

.stella-text {
  border-radius: 0.5625rem 0.5625rem 0.5625rem 0rem;
  border: 1px solid var(--New-purple, rgba(84, 28, 245, 0.19));
  background: #fff;
  box-shadow: 0px 4px 15px 0px rgba(0, 0, 0, 0.05);
  color: #2e2e2e;
  padding: 0.5rem;
  font-family: Inter;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 500;
  line-height: 120%; /* 1.05rem */
  max-width: 16rem;
  min-height: 1.6rem;
  max-height: max-content;
}

.user-chat {
  width: max-content;
  display: flex;
  flex-direction: column;
  padding: 0.5625rem 1.1875rem;
  margin-left: 80%;
  margin-right: 0;
  justify-content: flex-end;
  align-items: end;
  border-radius: 0.5625rem 0.5625rem 0rem 0.5625rem;
  border: 1px solid var(--Purple-stroke, rgba(84, 28, 245, 0.19));
  background: linear-gradient(
      281deg,
      rgba(84, 28, 245, 0.2) -10.49%,
      rgba(255, 255, 255, 0) 96.51%
    ),
    radial-gradient(
      314.45% 139.15% at 3.59% 3.24%,
      rgba(255, 255, 255, 0.49) 0%,
      rgba(255, 255, 255, 0.07) 100%
    );
  backdrop-filter: blur(14.393913269042969px);
  color: var(--main);
  font-family: Inter;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 500;
  line-height: 120%; /* 1.05rem */
}

.stella-options {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  margin-top: 0.3rem;
}

.stella-option {
  width: max-content;
  display: flex;
  padding: 0.40881rem 0.76656rem;
  align-items: flex-start;
  gap: 0.51106rem;
  border-radius: 0.5625rem;
  border: 0.818px solid var(--main);
}

.footer-btn {
  display: flex;
  /* justify-content: space-between; */
  gap: 50rem;
  margin-top: 2.5rem;
  cursor: pointer;
}

@media (max-width: 425px) {
  .stella {
    height: 50rem;
  }
  .stella-sidebar,
  .stella-bg-img {
    display: none;
  }
  .stella-container {
    width: 100%;
    box-shadow: none;
    border-radius: 0;
    margin: 0;
    padding: 0;
  }
  .stella-section {
    padding: 0;
  }
  .white-layout {
    padding: 0.5rem;
    width: 24.375rem;
    height: 6rem;
    flex-shrink: 0;
    background: #fff;
    box-shadow: 0px 4px 15px 0px rgba(0, 0, 0, 0.05);
  }
  .stella-name {
    gap: 2.5rem;
  }
  .name {
    max-width: 20rem;
    font-size: 1.25rem;
    line-height: 1.43919rem; /* 115.135% */
  }
  .talk-to-stella-container {
    width: 100%;
    height: max-content;
    border-radius: 0;
    padding: 1rem;
    margin: -2rem 0 0 0;
  }
  .stella-chat {
    gap: 0.5rem;
  }
  .user-chat {
    margin-left: 40%;
    margin-right: 0;
  }
  .footer-btn {
    gap: 5rem;
  }
}
