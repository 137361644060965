.PrimaryCTA {
  border-radius: 10px !important;
  background-color: #1b8bcf !important;
  display: flex !important;
  height: 50px;
  padding: 14px 24px !important;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
  color: #FFF !important;
  text-transform: capitalize !important;
}
.PrimaryCTA.Mui-disabled{
  background-color: #37acf38f !important;
}

.PrimaryCTA--Outlined {
  border-radius: 10px !important;
  background-color: #FFF !important;
  display: flex !important;
  height: 50px;
  padding: 14px 24px !important;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
  color: #1b8bcf !important;
  border: 1px solid #1b8bcf !important;
  text-transform: capitalize !important;
}
.PrimaryCTA--Outlined.Mui-disabled{
  background-color: #fbfbfb8f !important;
}

.GreenCTA {
  display: flex;
  height: 50px !important;
  padding: 14px 24px !important;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
  border-radius: 10px !important;
  background-color: #9dea66 !important;
  color: #21231E !important;
  text-transform: capitalize !important;
}

.GreenCTA.Mui-disabled{
  background-color: rgb(157 234 102 / 26%) !important;
  color: #666666 !important;
}

.RedCTA {
  display: flex;
  height: 50px !important;
  padding: 14px 24px !important;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
  border-radius: 10px !important;
  background: #DD263C !important;
  color: #FFF !important;
  text-transform: capitalize !important;
}
