.expect-section {
  width: 100%;
  max-height: 100vh;
  display: flex;
}

.expect-header {
  width: 100%;
  height: 5.5rem;
  box-shadow: 0px 0px 13px 0px #0000001a;
  display: flex;
  justify-content: center;
  gap: 1rem;
  align-items: center;
  scroll-behavior: smooth;
  animation: fadeIn 3s ease-in-out forwards;
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.expect-header a {
  font-family: inter;
  font-weight: 600;
  font-size: 1.2rem;
  line-height: 1.5rem;
  color: #191c1e;
  text-decoration: none;
  position: relative;
}

.expect-header a:hover {
  color: #541cf5;
}

/* underline animation */
.expect-header a::before {
  content: "";
  position: absolute;
  bottom: -5px;
  height: 3px;
  width: 0.2%;
  border-radius: 50px;
  background-color: #541cf5;
  transition: width 1s ease-in-out;
}

.expect-header a:hover::before {
  width: 100%;
}

.expect-main {
  display: flex;
  width: 50%;
  flex-direction: column;
  padding: 3rem 10rem;
  animation: slideUp 2s ease-in-out forwards;
  /* margin-bottom: 5rem; */
}

@keyframes slideUp {
  0% {
    transform: translateY(20%);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

.expect-left {
  display: flex;
  flex-direction: column;
  width: 65%;
}

.expect-left-text {
  width: 200%;
  padding-right: 6rem;
  padding-bottom: 2rem;
  font-family: Roboto;
}

.expect-left-heading {
  width: 100%;
  font-weight: 600;
  font-size: 1.5rem;
  line-height: 2rem;
  margin: 1rem 0;
}

.expect-left-para {
  font-weight: 400;
  font-size: 1.1rem;
  line-height: 1.75rem;
  justify-content: left;
}

.expect-left-blueSection {
  background-image: url("../../components/courseNew/assets/course-rectangle-desktop.png");
  width: 39rem;
  height: 15rem;
  border-radius: 1.1rem;
  /* position: absolute;
  top: 15rem;
  left: 10rem; */
  height: max-content;
  padding: 1.2rem;
  font-family: Roboto;
}

.expect-blue-heading {
  font-weight: 600;
  font-size: 1.25rem;
  line-height: 2rem;
}

.expect-blue-list {
  list-style: none;
  margin-left: -2rem;

  /* list-style-image: url("../../components/courseNew/assets/white_check_mark.png"); */
}

.check-list {
  width: 16px;
  height: 16px;
  margin-top: 0.4rem;
}

.expect-blue-list-items {
  display: flex;
  gap: 1rem;
  font-weight: 400;
  font-size: 1.1rem;
  line-height: 1.6rem;
}

/* .expect-right {
  padding: 5rem;
  margin-left: 5rem;
} */

.expect-right-img {
  width: 38rem;
  height: 28rem;
  margin-top: 8rem;
  margin-left: 5rem;
  /* max-width: 100%; */
}

@media (max-width: 425px) {
  .expect-header {
    width: 25rem;
    height: 5.5rem;
    gap: 0.6rem;
  }
  .expect-header a {
    font-size: 0.8rem;
    line-height: 1.2rem;
    color: #191c1e;
    text-decoration: none;
    position: relative;
  }
  .line {
    width: 0.0625rem;
    height: 1.75rem;
    flex-shrink: 0;
    opacity: 0.4;
    background: #b0c2c2;
  }
  .expect-section {
    flex-direction: column;
  }
  .expect-main {
    display: flex;
    width: 93%;
    flex-direction: column;
    padding: 1.5rem;
    animation: moveUp 3s ease-in forwards;
    /* margin-bottom: 5rem; */
  }
  .expect-left-heading {
    text-align: center;
    width: 100%;
    margin-left: 0;
  }
  .expect-left-para {
    width: 100%;
    font-size: 0.9rem;
    margin: 0 0.5rem;
    text-align: justify;
    line-height: 1.5rem;
  }
  .expect-left-blueSection {
    background-image: url("../../components/courseNew/assets/course-rectangle-mobile.png");
    width: 24.4rem;
    height: 21.5rem;
    border-radius: 0vw;
    position: absolute;
    top: 162rem;
    left: 0rem;
    overflow: hidden;
    height: max-content;
  }
  .expect-blue-list-items {
    font-weight: 400;
    text-align: justify;
    font-size: 1rem;
    line-height: 130%;
    width: 22rem;
  }
  .expect-right-img {
    width: 20rem;
    height: 17rem;
    position: relative;
    top: -10rem;
    left: -3rem;
  }
}

/* module section */
.module-section {
  width: 100%;
  height: 80vh;
  max-height: 80vh;
  margin-bottom: 0;
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
}

.module-heading {
  font-family: Roboto;
  font-weight: 700;
  font-size: 3rem;
  line-height: 3.2rem;
  text-align: center;
  /* padding: 1rem 0; */
  margin: 3rem 0 -2rem 10rem;
}

.card-slider {
  width: 60vw;
  height: 70vh;

  display: flex;
  margin: 0 0 2rem 20rem;
  /* gap: 2rem; */
  /* width: 20rem; */
}

.module-container {
  display: flex;
  flex-direction: column;
}

.module-card-section {
  display: flex;
  flex-direction: row;
  gap: 3rem;
  height: 80vh;
}

.module-card-image {
  margin-left: 5rem;
  /* background-size: cover;
  background-position: center; */
}

.module-card-content {
  /* display: none; */
  width: 40%;
  padding: 4rem 2rem;
  display: flex;
  flex-direction: column;
  gap: 3rem;
  z-index: 1;
}

.module-card-title {
  font-family: roboto;
  font-weight: 700;
  font-size: 1.8rem;
  line-height: 2.1rem;
}

.module-card-desc {
  font-family: roboto;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.6rem;
}

.module-arrow {
  position: absolute;
  bottom: 0;
  right: 0;
}

@media (max-width: 425px) {
  .module-section {
    margin-top: 8rem;
    max-height: 100vh;
  }
  .module-heading {
    font-size: 1.75rem;
    line-height: 110%;
    padding: 1rem 0;
    margin-left: 4.5rem;
    width: 16rem;
    margin-bottom: 1rem;
  }
  .card-slider {
    margin-top: -3rem;
    width: 100vw;
    height: 80vh;
    max-height: 80vh;
    margin: 2rem 0;
    /* gap: 2rem; */
    /* width: 20rem; */
  }
  .module-card-section {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-left: -5rem;
  }
  .module-cards {
    width: 100vw;
    height: max-content;
    max-height: 80vh;
    display: flex;
    margin-top: -5rem;
    /* gap: 2rem; */
    /* width: 20rem; */
  }
  .module-card-content {
    /* display: none; */
    min-height: max-content;
    width: 150%;
    padding: 0rem 5rem;
    margin-top: -1rem;
    margin-left: 0.75rem;
    gap: 1rem;
  }

  .module-card-title {
    font-family: roboto;
    font-weight: 700;
    font-size: 1.5rem;
    line-height: 120%;
  }
  .module-card-desc {
    max-height: 40rem;
    justify-items: center;
    font-family: roboto;
    font-weight: 400;
    font-size: 1rem;
    line-height: 130%;
  }
  .module-card-image {
    width: 100%;
    height: max-content;
    left: 0;
  }
}

/* course-section */
.course-section {
  width: 100%;
  max-height: max-content;
  min-height: 60vh;
  display: flex;
  flex-direction: row;
  padding: 5rem 12rem;

  background: linear-gradient(
    97.71deg,
    #f5f1ff -19.86%,
    rgba(233, 224, 255, 0) 110.5%
  );
}

.course-container {
  margin-left: 4rem;
  width: 31rem;
  height: 100%;
  /* margin-left: 5rem; */
}

.course-heading {
  font-family: roboto;
  font-weight: 600;
  font-size: 3rem;
  line-height: 4.5rem;
  letter-spacing: 3%;
}

.course-list {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  margin-top: 1rem;
  padding: 2rem 0;
}

.course-list-items {
  display: flex;
  gap: 2rem;
  cursor: pointer;
}

.course-list-check {
  border: 5px black;
  border-radius: 100%;
  width: 2.1rem;
  height: 2.1rem;
  background-color: white;
}

.course-list-img {
  width: 2rem;
  height: 2rem;
  min-width: 2rem;
  min-height: 2rem;
}

.course-list-heading {
  font-family: roboto;
  font-weight: 600;
  font-size: 1.4rem;
  line-height: 1.6rem;
  letter-spacing: 0.5%;
}

.course-list-para {
  padding: 0.3rem 0;
  font-size: 1.2rem;
  font-weight: 400;
  line-height: 1.6rem;
  letter-spacing: 0.75%;
  max-height: 0;
  overflow: hidden;
  transition: all 0.6s ease-in-out;
}

.course-list-para.show {
  height: auto;
  max-height: 9999px;
  transition: all 0.6s ease-in-out;
}

.course-btn {
  width: 18rem;
  height: 3.25rem;
  margin-top: 3rem;
  /* margin-left: 15rem; */
  gap: 1.5px;
  border-radius: 54px;
  padding: 0.8rem 3.1rem;
  background-color: #fd6f1f;
  font-family: helvetica;
  font-weight: 700;
  font-size: 1.1rem;
  line-height: 1.5rem;
  color: white;
  text-align: center;
  cursor: pointer;
}

.course-img {
  width: 30rem;
  max-width: 30rem;
  height: 20rem;
  /* max-height: 20rem; */
  margin-left: 10rem;
  margin-top: 2rem;
  animation: slideUp 2s ease-in-out forwards;
}

@keyframes slideUp {
  0% {
    transform: translateY(20%);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

@media (max-width: 425px) {
  .course-section {
    width: 105%;
    flex-direction: column-reverse;
    padding: 3rem 6rem;
    margin-top: 8rem;
  }
  .course-img {
    width: 20rem;
    height: max-content;
    margin-left: -4.5rem;
    margin-top: -1rem;
  }
  .course-container {
    margin-top: 2rem;
    margin-left: -4.5rem;
  }
  .course-heading {
    font-size: 1.5rem;
    line-height: 1.69825rem;
    letter-spacing: 0.01544rem;
    margin-bottom: -2rem;
  }
  .course-list-para {
    width: 70%;
    padding: 0.3rem 0;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.6rem;
    letter-spacing: 0.75%;
    max-height: 0;
    overflow: hidden;
    transition: all 0.6s ease-in-out;
  }
  .course-list-items {
    display: flex;
    gap: 1rem;
  }
  .course-btn {
    width: 16rem;
    height: 3.25rem;
    margin-top: -1.5rem;
    margin-left: 3rem;
    gap: 1.5px;
    border-radius: 54px;
    padding: 0.8rem 3.1rem;
    background-color: #fd6f1f;
    font-family: helvetica;
    font-weight: 700;
    font-size: 1.1rem;
    line-height: 1.5rem;
    color: white;
    text-align: center;
  }
}

/* meet your instructor */
.instructor-section {
  width: 100%;
  height: 77vh;
  max-height: 77vh;
}

.instructor-container {
  width: 100%;
  display: flex;
  gap: 10rem;
}

.instructor-image {
  margin-top: -6rem;
  width: 60vw;
  height: 100%;
  animation: scaleAnimation 5s ease-in-out forwards;
}

@keyframes scaleAnimation {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}

.instructor-data {
  display: flex;
  flex-direction: column;
  padding: 0.5rem 6rem;
  width: 50%;
  font-family: roboto;
  animation: slideUp 2s ease-in-out forwards;
}

@keyframes slideUp {
  0% {
    transform: translateY(20%);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

.instructor-heading {
  font-weight: 700;
  font-size: 2.8rem;
  line-height: 2.5rem;
  letter-spacing: 2%;
  margin-left: 62rem;
  margin-top: 3rem;
  margin-bottom: -2rem;
}

.instructor-namee {
  display: flex;
  gap: 1rem;
  padding: 1rem 0;
}

.instructor-name {
  font-size: 2rem;
  font-weight: 700;
  line-height: 2rem;
  width: 70%;
}

.instructor-expertise {
  font-family: inter;
  font-weight: 500;
  font-size: 1.1rem;
  line-height: 1.1rem;
  padding: 0.2rem;
  text-align: center;
  background-color: #e0f6d8;
  color: #134700;
  border-radius: 0.5rem;
  width: fit-content;
  margin-bottom: 1rem;
}

.instructor-rating {
  display: flex;
  gap: 0.5rem;
  font-family: inter;
  font-weight: 600;
  font-size: 1.05rem;
  line-height: 1.5rem;
  letter-spacing: 0.1rem;
}

.instructor-experience {
  font-family: inter;
  font-weight: 600;
  font-size: 1.1rem;
  line-height: 1.6rem;
  margin: 1rem 0;
}

.instructor-language {
  font-family: inter;
  font-weight: 500;
  font-size: 1.1rem;
  line-height: 1.6rem;
  margin: 1rem 0;
}

.instructor-desc {
  width: 27rem;
  height: 14rem;
  font-family: roboto;
  font-weight: 400;
  font-size: 1.1rem;
  line-height: 1.7rem;
  text-align: left;
  margin: 0.5rem 0;
}

@media (max-width: 425px) {
  .instructor-section {
    width: 100%;
    height: max-content;
    max-height: 125vh;
  }
  .instructor-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
  .instructor-heading {
    font-weight: 700;
    font-size: 2rem;
    line-height: 1.75rem;
    letter-spacing: 2%;
    width: 100%;
    margin-top: 1rem;
    margin-left: 3rem;
  }
  .instructor-image {
    margin-top: 1rem;
    padding: 1rem;
    max-width: 25rem;
    max-height: 18.5rem;
  }
  .instructor-data {
    display: flex;
    flex-direction: column;
    padding: 0.5rem 1rem;
    width: 100%;
    font-family: roboto;
    animation: moveUp 3s ease-in forwards;
  }
  .instructor-desc {
    width: 19rem;
    height: 30rem;
    max-height: max-content;
    font-family: roboto;
    font-weight: 400;
    font-size: 1.1rem;
    line-height: 1.7rem;
    justify-content: left;
    padding-right: 0.25rem;
    margin-bottom: -3rem;
  }
}

/* results */
.results-section {
  width: 100%;
  height: 50vh;
  background: linear-gradient(
    103.69deg,
    #f1f5ff 0%,
    rgba(255, 248, 243, 0) 108.17%
  );
}

.results-container {
  padding: 3rem 10rem;
}

.results-heading {
  font-family: roboto;
  font-weight: 700;
  font-size: 3rem;
  line-height: 3.2rem;
  margin-left: 40%;
}

.results-list {
  width: 100%;
  height: 11rem;
  display: grid;
  gap: 2rem;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: 1fr;
  padding: 4rem;
  margin-left: 4rem;
  justify-content: space-between;
}

.results-list-items {
  width: 90%;
  height: 10.25rem;
  opacity: 0;
  transform: translateY(75px);
  animation: slideUp 0.8s ease-in-out forwards;
}

.results-list-items:nth-child(1) {
  animation-delay: 0.1s; /* Adjust delay for the first item */
}

.results-list-items:nth-child(2) {
  animation-delay: 0.3s; /* Adjust delay for the second item */
}

.results-list-items:nth-child(3) {
  animation-delay: 0.5s; /* Adjust delay for the third item */
}

.results-list-items:nth-child(4) {
  animation-delay: 0.7s; /* Adjust delay for the fourth item */
}

/* @keyframes slideUp {
  0% {
    transform: translateY(20%);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
} */

.results-list-heading {
  font-weight: 700;
  font-size: 4rem;
  line-height: 4rem;
  letter-spacing: 2%;
}

.results-list-desc {
  font-family: roboto;
  font-weight: 500;
  font-size: 1.2rem;
  line-height: 2rem;
  letter-spacing: 2%;
  padding: 0.5rem 0;
}

.img-star {
  width: 40px;
  height: 40px;
}

.img-google {
  width: 189px;
  height: 65px;
}

@media (max-width: 425px) {
  .results-section {
    width: 100%;
    height: 60vh;
  }
  .results-container {
    padding: 3rem 1rem;
  }
  .results-heading {
    font-family: roboto;
    font-weight: 700;
    font-size: 2.5rem;
    line-height: 3rem;
    text-align: center;
    width: 100%;
    margin-left: 0rem;
  }
  .results-list {
    margin-left: -3rem;
    width: 70vw;
    height: 45vh;
    display: grid;
    gap: 2rem;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
    justify-content: space-between;
    animation: fadeInUp 0.5s ease-in-out forwards;
  }
  .results-list-items {
    display: flex;
    flex-direction: column;
    width: 10rem;
    height: 5rem;
  }
  .results-list-heading {
    font-weight: 700;
    font-size: 1.9rem;
    line-height: 1.8rem;
    letter-spacing: 2%;
  }
  .results-list-desc {
    width: 100%;
    font-family: roboto;
    font-weight: 500;
    font-size: 1rem;
    line-height: 1.5rem;
    letter-spacing: 2%;
    padding: 0.5rem 0;
  }
  .img-star {
    width: 30px;
    height: 30px;
  }
  .img-google {
    width: 75px;
    height: 30px;
  }
}
