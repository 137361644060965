:root {
  --primary: #000;
  --secondary: #fff;
  --clrblue: #541cf5;
  --clrorange: #f25d0f;
  --clrsky: #1ae9ff;
  --clrgreen: #aef05e;
  --ftbg: #c6b1ff;
}

// body {
//     font-family: "Roboto", sans-serif;
//     font-family: "Lateef", serif;
// }
.new-plans {
  a {
    color: var(--bs-blue);
    text-decoration: none;
  }
  ul {
    margin: 0;
    padding: 0;
  }
  h2.heading {
    font-family: Roboto;
    font-size: 65px;
    font-weight: 600;
    line-height: 90px;
    letter-spacing: 0px;
    text-align: center;
    color: var(--primary);
    width: 100%;
    float: left;
  }
  h2.heading1 {
    font-family: Roboto;
    font-size: 65px;
    font-weight: 600;
    line-height: 90px;
    letter-spacing: 0px;
    text-align: center;
    color: var(--secondary);
  }
  .text-lf {
    text-align: left !important;
  }
  .cl-wh {
    color: var(--secondary) !important;
  }
  .desktop-display-none {
    display: none;
  }
  .desktop-display {
    display: block;
  }

  /* ********* Main CSS************** */
  .main {
    width: 100%;
    float: left;
  }

  /* ********* Top-Nav-Area CSS************** */

  .top-nav {
    width: 100%;
    float: left;
    background-color: var(--primary);
    height: 44px;
  }
  .top-nav-text {
    width: 100%;
    float: left;
  }

  .top-nav-text p {
    color: var(--secondary);
    font-family: "Roboto";
    font-weight: 400;
    font-size: 14px;
    line-height: 18.75px;
    text-align: right;
    margin-top: 13px;
  }
  .top-nav-btn {
    width: 100%;
    float: left;
  }

  .top-nav-btn button.btn {
    width: 35%;
    border-radius: 23px;
    border: 1px solid var(--secondary);
    float: left;
    padding: 2px;
    margin-top: 7px;
  }

  .top-nav-btn button.btn a {
    color: var(--secondary);
    font-family: Roboto;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: -0.005em;
    text-align: center;
  }

  /* ********* Nav-Area CSS************** */
  .nav-item {
    cursor: pointer;
  }

  .nav-item:hover .dropdown-menu {
    display: block !important;
  }

  a.dropdown-item {
    font-family: "Roboto";
  }
  .nav-area {
    width: 100%;
    float: left;
  }
  a.navbar-brand {
    width: 20%;
    float: left;
  }
  a.nav-link {
    font-family: "Roboto";
    font-size: 17px;
  }
  a.navbar-brand img {
    width: 85%;
  }
  .nav-login-btn {
    width: 10%;
    float: left;
  }

  .nav-login-btn button.btn {
    background-color: var(--clrblue);
    padding: 5px 25px;
    border-radius: 25px;
    color: var(--secondary);
  }
  .nav-login-btn button.btn a {
    color: var(--secondary);
    font-family: "Roboto";
    font-size: 15px;
    font-weight: 500;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: center;
  }
  .navbar-toggler:focus {
    text-decoration: none;
    outline: 0;
    box-shadow: none;
  }
  .search-container {
    width: 308px;
    /* display: block; */
    /* margin: 0 auto; */
    float: left;
    height: 45px;
  }

  input#search-bar {
    margin: 0 auto;
    width: 100%;
    height: 45px;
    padding: 0 20px;
    font-size: 1rem;
    border: 1px solid #541cf5;
    outline: none;
    focus {
      border: 1px solid #008abf;
      transition: 0.35s ease;
      color: #008abf;
      &::-webkit-input-placeholder {
        transition: opacity 0.45s ease;
        opacity: 0;
      }
      &::-moz-placeholder {
        transition: opacity 0.45s ease;
        opacity: 0;
      }
      &:-ms-placeholder {
        transition: opacity 0.45s ease;
        opacity: 0;
      }
    }
    border-radius: 30px;
    font-family: "Lato";
  }

  .search-icon {
    position: relative;
    float: right;
    width: 25px;
    height: 25px;
    top: -36px;
    right: 11px;
  }

  /* ********* Hero-Area CSS************** */

  .hero-area {
    width: 100%;
    float: left;
    background-image: url(../../../public/assets/img/bgsec.jpg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    padding: 90px 0;
    height: 740px;
  }
  .hero-area.blueGradient {
    background-image: url(../../../public/assets/img/bgSecBlue.png);
  }

  .hero-text {
    width: 100%;
    float: left;
    padding-right: 80px;
  }

  .hero-img {
    width: 100%;
    float: left;
  }

  .img1 {
    width: 100%;
    float: left;
    margin-left: -90px;
  }

  .img1 img {
    width: 100%;
    float: left;
    margin-top: 93px;
  }
  .img2 {
    width: 23%;
    float: left;
    position: absolute;
    right: 15.9%;
  }

  .img2 img {
    width: 100%;
    float: left;
  }
  .img3 {
    width: 100%;
    float: left;
  }

  .img3 img {
    width: 100%;
    float: left;
    margin-top: 13px;
  }
  .img4 {
    width: 100%;
    float: left;
  }

  .img4 img {
    width: 100%;
    float: left;
  }
  .img5 {
    width: 100%;
    float: left;
  }

  .img5 img {
    width: 100%;
    float: left;
    margin-top: 20px;
  }
  .hero-text h1 {
    font-family: Roboto;
    font-size: 65px;
    font-weight: 600;
    line-height: 84px;
    letter-spacing: 0px;
    text-align: left;
    color: var(--primary);
  }
  span.diff-clr {
    color: var(--clrblue);
  }
  .hero-text h4 {
    font-family: Roboto;
    font-size: 23px;
    font-weight: 700;
    line-height: 47px;
    letter-spacing: 0em;
    text-align: left;
    margin-bottom: 35px;
  }
  .icon-area {
    width: 100%;
    float: left;
  }

  .icon-area h5 {
    font-family: Roboto;
    font-size: 20px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0px;
    text-align: left;
    width: 100%;
    float: left;
  }

  .icon-area p {
    font-family: Roboto;
    font-size: 16px;
    font-style: italic;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0px;
    text-align: left;
    width: 100%;
    float: left;
  }
  .icon-bg {
    background-color: var(--secondary);
    width: 30%;
    padding: 14px;
    border-radius: 53px;
    margin-bottom: 20px;
  }

  .icon-bg img {
    width: 100%;
  }
  .Subscribe-btn {
    width: 100%;
    float: left;
    display: flex;
    // justify-content: center;
  }

  .Subscribe-btn button.btn {
    background-color: var(--clrorange);
    padding: 13px 45px;
    border-radius: 44px;
    color: var(--secondary);
    // margin: 40px 0 15px;
  }
  .Subscribe-btn button.btn a {
    color: var(--secondary);
    font-family: "Roboto";
    font-size: 18px;
    font-weight: 500;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: center;
  }
  .btm-text {
    font-family: Roboto;
    font-size: 19px;
    font-weight: 700;
    line-height: 30px;
    letter-spacing: 0em;
    text-align: left;
  }
  .whats-app {
    width: 100%;
    float: right;
    position: fixed;
    bottom: 30px;
  }

  .whats-app img {
    text-align: right;
    float: right;
    margin-top: -30px;
    width: 7%;
  }

  /* ********* Personal-Data-Area CSS************** */

  .personal-data-area {
    width: 100%;
    float: left;
    margin-top: -70px;
  }

  .personal-data-inner {
    width: 100%;
    float: left;
    padding: 30px 60px 20px 60px;
    border-radius: 7px;
    // box-shadow: 0px 4px 19px 0px #0000001f;
    // background-color: var(--secondary);
  }

  .personal-data-text {
    width: 100%;
    float: left;
  }

  .personal-data-img {
    width: 100%;
    float: right;
    margin-top: 0;
  }

  .personal-data-img ul {
    width: 100%;
    float: right;
  }

  .personal-data-img ul li {
    list-style: none;
    display: inline-block;
  }

  .personal-data-img ul li img {
    width: 77%;
    float: right;
  }
  .personal-data-text h3 {
    font-family: "Roboto";
    font-size: 32px;
    font-weight: 600;
    line-height: 43px;
    letter-spacing: 0em;
    text-align: left;
    margin-top: 30px;
  }

  /* ********* Pick-Plan-Area CSS************** */

  .pick-plan-area {
    width: 100%;
    float: left;
    margin: 40px 0;
  }

  .plan-bg {
    box-shadow: 0px 4px 16px 0px #00000029;
    background-image: url(../../../public/assets/img/planFree_1.png);
    background-repeat: no-repeat;
    border-radius: 7px;
    padding: 35px 30px;
    height: 704px;
    margin: 20px 0px 20px 10px;
  }

  .plan h3 {
    font-family: "Roboto";
    font-size: 32px;
    font-weight: 700;
    line-height: 38px;
    letter-spacing: 2px;
    text-align: left;
  }

  .plan {
    width: 100%;
    float: left;
  }

  .plan h6 {
    font-family: "Roboto";
    font-size: 14px;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: left;
    margin-bottom: 20px;
  }

  .plan p {
    font-family: "Roboto";
    font-size: 16px;
    font-weight: 400;
    line-height: 29px;
    letter-spacing: 0em;
    text-align: left;
  }

  span.price {
    font-family: "Roboto";
    font-size: 28px;
    font-weight: 700;
    line-height: 29px;
    letter-spacing: 0em;
    text-align: left;
  }

  .plan h4 {
    font-family: "Roboto";
    font-size: 16px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: left;
  }

  .plan ul {
    width: 100%;
    float: left;
  }

  .plan ul li {
    list-style: none;
    border-bottom: 1px solid #ddd;
    padding: 7px 0;
  }

  .plan ul li img {
    width: 7%;
    float: left;
    margin-right: 4%;
    margin-top: 3px;
  }

  .plan ul li h5 {
    font-family: Roboto;
    font-size: 20px;
    font-weight: 600;
    line-height: 25px;
    letter-spacing: 0em;
    text-align: left;
  }

  .plan ul li p {
    margin-left: 12%;
    font-family: Roboto;
    font-size: 17px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    color: var(--primary);
    margin-bottom: 6px;
  }
  .plan-btn {
    width: 100%;
    float: left;
  }

  .plan-btn button.btn {
    background-color: var(--clrorange);
    padding: 9px 45px;
    border-radius: 44px;
    color: var(--secondary);
    margin: 96px auto 0;
    display: block;
  }
  .plan-btn button.btn a {
    color: var(--secondary);
    font-family: "Roboto";
    font-size: 18px;
    font-weight: 500;
    line-height: 28px;
    letter-spacing: 0em;
    text-align: center;
  }
  hr {
    width: 70%;
    color: #ffffff;
    border-top: 2px solid;
    margin-top: 0px;
  }
  .plan-bg1 {
    box-shadow: 0px 4px 16px 0px #00000029;
    border-radius: 7px;
    padding: 30px 30px;
    height: 760px;
    background-image: url(../../../public/assets/img/plan1_1.png);
    background-position: right;
    background-repeat: no-repeat;
    background-size: cover;
  }
  .plan1 {
    width: 100%;
    float: left;
  }
  .plan1 h3 {
    font-family: "Roboto";
    font-size: 30px;
    font-weight: 700;
    line-height: 38px;
    letter-spacing: 2px;
    text-align: left;
    color: var(--secondary);
  }
  .plan1 h6 {
    font-family: "Roboto";
    font-size: 14px;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: left;
    color: var(--secondary);
    margin-bottom: 16px;
  }

  .plan1 p {
    font-family: "Roboto";
    font-size: 16px;
    font-weight: 400;
    line-height: 29px;
    letter-spacing: 0em;
    text-align: left;
    color: var(--secondary);
    margin-bottom: 1px;
  }

  span.price {
    font-family: "Roboto";
    font-size: 28px;
    font-weight: 700;
    line-height: 29px;
    letter-spacing: 0em;
    text-align: left;
  }

  .plan1 h4 {
    font-family: "Roboto";
    font-size: 16px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: left;
    color: var(--secondary);
  }

  .plan1 ul {
    width: 100%;
    float: left;
  }

  .plan1 ul li {
    list-style: none;
    border-bottom: 1px solid #ddd;
    padding: 7px 0;
  }

  .plan1 ul li img {
    width: 7%;
    float: left;
    margin-right: 4%;
    margin-top: 3px;
  }

  .plan1 ul li h5 {
    font-family: Roboto;
    font-size: 18px;
    font-weight: 600;
    line-height: 25px;
    letter-spacing: 0em;
    text-align: left;
    color: var(--secondary);
  }

  .plan1 ul li p {
    margin-left: 12%;
    font-family: Roboto;
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: left;
    color: var(--secondary);
    margin-bottom: 6px;
  }
  .plan1-btn {
    width: 100%;
    float: left;
  }

  .plan1-btn button.btn {
    background-color: var(--clrorange);
    // padding: 9px 20px;
    border-radius: 44px;
    color: var(--secondary);
    margin: 40px auto;
  }
  .plan1-btn button.btn a {
    color: var(--secondary);
    font-family: "Roboto";
    font-size: 18px;
    font-weight: 500;
    line-height: 28px;
    letter-spacing: 0em;
    text-align: center;
  }
  .plan2-btn {
    width: 100%;
    float: left;
  }

  .plan2-btn button.btn {
    border: 1px solid var(--secondary);
    // padding: 9px 36px;
    border-radius: 44px;
    color: var(--secondary);
    margin: 40px auto;
  }
  .plan2-btn button.btn a {
    color: var(--secondary);
    font-family: "Roboto";
    font-size: 18px;
    font-weight: 500;
    line-height: 28px;
    letter-spacing: 0em;
    text-align: center;
  }
  .plan1-para {
    width: 100%;
    float: left;
    background-image: url(../../../public/assets/img/bgsec6.jpg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    margin-top: -64px;
    text-align: center;
    padding: 10px 17px;
    border-radius: 0 0 7px 7px;
  }

  .plan1-para p {
    font-family: Roboto;
    font-size: 17px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: center;
    color: var(--secondary);
    margin-bottom: 0;
    font-style: normal;
  }
  .seller-area {
    width: 22%;
    float: right;
    margin-top: -188px;
    margin-right: -16px;
  }

  .seller-area img {
    margin: 0 auto;
    display: block;
  }

  .seller-area p {
    font-family: Roboto;
    font-size: 16px;
    font-weight: 700;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: center;
  }
  .plan-bg2 {
    box-shadow: 0px 4px 16px 0px #00000029;
    border-radius: 7px;
    padding: 74px 30px;
    height: 753px;
    background-image: url(../../../public/assets/img/plan2_1.png);
    background-position: right;
    background-repeat: no-repeat;
    background-size: cover;
  }

  .plan-bg3 {
    box-shadow: 0px 4px 16px 0px #00000029;
    border-radius: 7px;
    padding: 74px 30px;
    height: 753px;
    background-image: url(../../../public/assets/img/plan3_1.png);
    background-position: right;
    background-repeat: no-repeat;
    background-size: cover;
  }

  .m-t {
    margin-top: 20px;
    padding: 36px 30px;
    height: 735px;
  }
  .new {
    margin-top: 21px !important;
  }
  .m-t-n {
    margin-top: -157px;
  }

  /* ********* Compare-Plan-Area CSS************** */

  .compare-plan-area {
    width: 100%;
    float: left;
    // background-image: url(../../../public/assets/img/bgsec5.jpg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    padding: 20px 0 80px;
  }
  .compare-plan-inner {
    width: 100%;
    float: left;
  }

  .text-roboto {
    font-family: Roboto;
    font-style: normal;
  }

  .compare-plan-inner-mobile h3 {
    font-family: Roboto;
    font-size: 8px;
    font-weight: 700;
    font-style: normal;
    line-height: 4px;
    white-space: nowrap;
    margin-bottom: 0 !important;
  }

  .compare-plan-inner-mobile button {
    background: #f25d0f;
    color: #fff;
    text-align: center;
    font-family: Roboto;
    font-size: 8px;
    font-style: normal;
    font-weight: 700;
    line-height: 8.15px;
    border: none;
  }

  .compare-plan-inner-mobile .compare-plan-learnMore-link {
    color: #f25d0f;
    text-align: center;
    font-family: Roboto;
    font-size: 8px !important;
    font-style: normal;
    font-weight: 600;
    line-height: 3.076px;
  }

  .compare-plan-inner-desktop h3 {
    color: #000;
    font-family: Roboto;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 14px;
    margin-bottom: 0 !important;
  }

  .compare-plan-inner-desktop button {
    background: #f25d0f;
    color: #fff;
    text-align: center;
    font-family: Roboto;
    font-size: 21.696px;
    font-style: normal;
    font-weight: 700;
    line-height: 34.07px;
    border: none;
  }

  .compare-plan-inner-desktop .compare-plan-learnMore-link {
    color: #f25d0f;
    text-align: center;
    font-family: Roboto;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 12px;
  }

  .compare-plan-area p {
    font-family: Roboto;
    font-size: 24px;
    font-weight: 500;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: center;
    margin: 0px 0 50px;
    color: #404040;
  }

  .css-1yk1gt9-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root {
    color: #541cf5 !important;
    text-align: center;
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 700; 
  }

  .css-jedpe8-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-jedpe8-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-jedpe8-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input{
    padding-right: 25px !important;
  }

  table {
    font-family: arial, sans-serif;
    border-collapse: collapse;
    width: 100%;
  }

  td,
  th {
    border: 1px solid #ffffff;
    text-align: left;
    padding: 8px;
  }

  // tr:nth-child(even) {
  //   background-color: #ffffff !important;
  // }

  th.md {
    font-family: Roboto;
    font-size: 27px;
    font-weight: 700;
    line-height: 47px;
    letter-spacing: 0em;
    text-align: left;
    width: 40%;
    padding: 20px 30px;
    background-color: var(--secondary);
    border: none;
  }
  th.care {
    font-family: Roboto;
    font-size: 22px;
    font-weight: 700;
    line-height: 47px;
    letter-spacing: 0em;
    width: 22%;
    padding: 20px 30px;
    background-color: var(--secondary);
    border: none;
    text-align: center;
  }

  th.pre {
    width: 20%;
    padding: 20px 30px 28px;
    background-color: var(--secondary);
    border: none;
  }

  th.plat {
    width: 20%;
    padding: 20px 30px 28px;
    background-color: var(--secondary);
    border: none;
  }
  .icon-img {
    display: block;
    margin: 0 auto;
    width: 10%;
  }
  th.plat h3 {
    font-family: Roboto;
    font-size: 22px;
    font-weight: 700;
    letter-spacing: 0em;
    text-align: center;
    color: var(--secondary);
    margin-top: -43px;
  }
  th.pre h3 {
    font-family: Roboto;
    font-size: 22px;
    font-weight: 700;
    letter-spacing: 0em;
    text-align: center;
    color: var(--secondary);
    margin-top: -43px;
  }
  td {
    border: none;
  }
  td.bg-custom {
    padding: 15px 30px;
  }
  .bg-white {
    background-color: #fff;
  }
  .bg-wh {
    background-color: var(--secondary);
    font-family: Roboto;
    font-size: 16px;
    font-weight: 500;
    line-height: 28px;
    letter-spacing: 0em;
    text-align: left;
  }
  td.pr-tbl span {
    font-family: Roboto;
    font-size: 20px;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: center;
    margin-bottom: 16px;
    width: 100%;
    float: left;
  }

  .tbl-btn {
    margin: 0px auto 10px !important;
    padding: 2px 20px !important;
  }

  .tbl-btn a {
    font-size: 14px !important;
  }

  td.pr-tbl {
    padding: 15px 7px;
    vertical-align: middle !important;
  }
  td.pr-tbl span p {
    font-size: 14px;
    color: #404040;
  }
  .learn-btn {
    padding: 0 !important;
    background-color: transparent !important;
    font-family: "Roboto";
    font-size: 18px;
    font-weight: 500;
    line-height: 28px;
    letter-spacing: 0em;
    text-align: center;
    margin: 0 auto !important;
  }
  .learn-btn a {
    color: var(--clrorange) !important;
    font-size: 16px;
  }

  /* ********* Choice-Area CSS************** */

  .choice-area {
    width: 100%;
    float: left;
    background-image: url(../../../public/assets/img/bgsec7.jpg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    padding: 90px 0;
  }
  .choice-inner {
    width: 100%;
    float: left;
  }
  .circle-big {
    position: relative;
    width: 114px;
    height: 114px;
    margin: 30px auto 25px auto;
  }

  .circle-big svg {
    width: 114px;
    height: 114px;
  }

  .circle-big .bg {
    fill: none;
    stroke-width: 10px;
    stroke: #1ae9ff4a;
  }

  .circle-big .progress {
    fill: none;
    stroke-width: 10px;
    stroke: var(--clrsky);
    stroke-linecap: round;
    stroke-dasharray: 326.56;
    stroke-dashoffset: 60;
    transform: rotate(-90deg);
    transform-origin: 50% 50%;
    animation: big 1.5s ease-in-out;
  }

  .circle-big .text {
    position: absolute;
    width: 100%;
    top: 46px;
    text-align: center;
    line-height: 22px;
    font-family: Roboto;
    font-size: 31px;
    font-weight: 700;
    color: var(--secondary);
  }

  @keyframes big {
    from {
      stroke-dashoffset: 326.56;
    }
    to {
      stroke-dashoffset: 86;
    }
  }

  @keyframes one {
    from {
      stroke-dashoffset: 232.36;
    }
    to {
      stroke-dashoffset: 80;
    }
  }

  @keyframes two {
    from {
      stroke-dashoffset: 232.36;
    }
    to {
      stroke-dashoffset: 140;
    }
  }

  @keyframes appear {
    0%,
    50% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  p.c-para {
    font-family: "Roboto";
    font-size: 25px;
    font-weight: 400;
    line-height: 36px;
    letter-spacing: 0em;
    text-align: center;
    color: var(--secondary);
    padding: 0 50px;
  }

  /* ********* Trust-Area CSS************** */

  .trust-area {
    width: 100%;
    float: left;
    background-image: url(../../../public/assets/img/bgsec1.jpg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    padding: 90px 0;
  }
  .trust-area.pinkGradient {
    background-image: url(../../../public/assets/img/planDetails/ReviewCarouselBG.png);
  }

  .trust-inner {
    width: 100%;
    float: left;
    margin-top: 60px;
  }

  .trust-area span.diff-clr {
    color: var(--clrgreen);
  }
  .trust1 {
    width: 100%;
    margin: 0 auto;
    display: block;
    text-align: center;
  }

  .trust1 img {
    width: 20%;
  }
  .trust1 h3 {
    font-family: Roboto;
    font-size: 45px;
    font-weight: 800;
    line-height: 89px;
    letter-spacing: 0.5940239429473877px;
    text-align: center;
    color: var(--secondary);
  }

  .trust1 p {
    font-family: Roboto;
    font-size: 20px;
    font-weight: 400;
    line-height: 32px;
    letter-spacing: 0.2376095950603485px;
    text-align: center;
    color: var(--secondary);
  }
  .trust2 {
    width: 100%;
    margin: 0 auto;
    display: block;
    text-align: center;
  }

  .trust2 img {
    width: 39%;
  }
  .trust2 h3 {
    font-family: Roboto;
    font-size: 45px;
    font-weight: 800;
    line-height: 89px;
    letter-spacing: 0.5940239429473877px;
    text-align: center;
    color: var(--secondary);
  }

  .trust2 p {
    font-family: Roboto;
    font-size: 20px;
    font-weight: 400;
    line-height: 32px;
    letter-spacing: 0.2376095950603485px;
    text-align: center;
    color: var(--secondary);
  }

  .trust1 hr {
    border: 1px solid #fff;
    width: 35%;
    margin: 0 auto;
    margin-top: 25px;
  }

  /* ********* Reason-Area CSS************** */

  .reason-area {
    width: 100%;
    float: left;
  }

  .reason-inner {
    width: 100%;
    float: left;
    background-image: url(../../../public/assets/img/bgsec4.jpg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    padding: 0px 0 0 45px;
    border-radius: 7px;
    margin-top: 90px;
  }

  .reason-area.yellowGradient .reason-inner {
    background-image: url(../../../public/assets/img/yellowGradient.png);
    margin-bottom: 90px;
  }

  .reason-inner h4 {
    font-family: "Roboto";
    font-size: 32px;
    font-weight: 600;
    line-height: 43px;
    letter-spacing: 0em;
    text-align: left;
    // margin-top: 45px;
    color: var(--secondary);
  }
  .reason-area.yellowGradient .reason-inner h4 {
    color: var(--primary);
  }

  .reason-inner img {
    width: 125%;
  }

  .Subscribe-btn img {
    width: 13%;
    margin-right: 15px;
  }
  .reason-btn {
    padding: 10px 13px !important;
    // margin-top: 60px !important;
  }

  /* ********* Partner-Area CSS************** */

  .partner-area {
    width: 100%;
    float: left;
    margin: 90px 0;
  }

  .partner-inner {
    width: 100%;
    float: left;
  }
  .partner-inner img {
    width: 90%;
    margin: 0 auto;
    display: block;
  }

  /* ********* Client-Area CSS************** */

  .client-area {
    width: 100%;
    float: left;
    background-image: url(../../../public/assets/img/bgsec2.jpg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    padding: 90px 0;
  }
  .client-inner {
    width: 100%;
    float: left;
  }

  .client-up {
    width: 100%;
    float: left;
    margin-bottom: 50px;
    margin-top: 35px;
  }

  .client-up img {
    width: 90%;
    margin: 0 auto;
    display: block;
  }
  .client-down {
    width: 100%;
    float: left;
    margin-bottom: 50px;
  }

  .client-down img {
    width: 90%;
    margin: 0 auto;
    display: block;
  }

  /* ********* Best-Area CSS************** */

  .best-area {
    width: 100%;
    float: left;
    background-image: url(../../../public/assets/img/bgsec3.jpg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    padding: 90px 0;
  }
  .best-inner {
    width: 100%;
    float: left;
  }
  .best-inner span.diff-clr {
    color: var(--clrgreen);
  }
  .best-inner img {
    width: 24%;
    margin: 55px auto 30px;
    display: block;
  }
  .best-inner ul {
    width: 100%;
    float: left;
  }

  .best-inner ul li {
    list-style: none;
    font-family: Roboto;
    font-size: 28px;
    font-weight: 500;
    line-height: 59px;
    letter-spacing: 0em;
    text-align: left;
    color: var(--secondary);
    margin-bottom: 25px;
  }

  .best-inner ul li img {
    width: 3%;
    float: left;
    margin: 14px;
  }
  button.btn.center-btn {
    background-color: var(--clrorange);
    padding: 13px 50px;
    border-radius: 44px;
    color: var(--secondary);
    margin: 40px auto;
    display: block;
  }

  /* ********* Review-Area CSS************** */

  .review-area {
    width: 100%;
    float: left;
    background-image: url(../../../public/assets/img/bgsec4.jpg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    padding: 90px 0;
  }
  .review-inner {
    width: 100%;
    float: left;
  }
  .review-inner img {
    width: 100%;
    float: left;
  }
  img.review-bg {
    width: 50%;
    margin-top: -36%;
  }
  h2.heading.text-lf.cl-wh img {
    width: 7%;
    float: right;
    // margin-top: 49px;
    margin-right: -20px;
  }
  img.google-img {
    width: 27%;
    float: left;
  }

  img.rat-img {
    width: 100%;
    float: left;
    margin-top: 50px;
  }
  img.more-img {
    width: 75%;
    float: right;
  }

  /* ********* Team-Area CSS************** */

  .team-area {
    width: 100%;
    float: left;
    padding: 40px 0;
  }
  .our-team {
    position: relative;
    overflow: hidden;
    margin-top: 35px;
    width: 25%;
    float: left;
  }
  .our-team img {
    width: 100%;
  }
  .our-team .team-content {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    padding: 45px 18px;
    background: rgba(0, 0, 0, 0.7);
    transform: translateX(-100%);
    transition: all 0.2s ease 0s;
    text-align: center;
  }
  .our-team:hover .team-content {
    transform: translateX(0);
  }
  .our-team .team-content .post-title {
    font-family: "Roboto";
    font-size: 32px;
    font-weight: 700;
    line-height: 48px;
    letter-spacing: -0.02em;
    color: #f5f7c6;
    text-align: center;
  }
  .our-team .team-content .post {
    font-size: 14px;
    color: #cb95e1;
    display: block;
    margin-bottom: 20px;
  }
  .our-team .description {
    font-family: lato;
    font-size: 17px;
    font-weight: 400;
    line-height: 31px;
    letter-spacing: 1.056249976158142px;
    text-align: center;
    color: var(--secondary);
  }
  .our-team .team_social {
    margin: 0;
    padding: 0;
    list-style: none;
  }
  .our-team .team_social li {
    display: inline-block;
    margin-right: 5px;
  }
  .our-team .team_social li a {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border: 2px solid #f5f5f5;
    font-size: 17px;
    color: #f5f5f5;
    line-height: 40px;
    text-align: center;
    display: inline-block;
    transition: border 0.3s ease 0s;
  }
  .our-team .team_social li a:hover {
    border-color: transparent;
  }
  .our-team .team-prof {
    width: 100%;
    position: absolute;
    bottom: 0;
    text-align: center;
    padding: 20px 16px;
    background: linear-gradient(180deg, #fff0, #000000d1);
    transform: translateX(0);
    transition: all 0.2s ease 0s;
  }
  .our-team:hover .team-prof {
    transform: translateX(100%);
  }
  .our-team .team-prof .post-title {
    font-family: "Roboto";
    font-size: 32px;
    font-weight: 700;
    line-height: 48px;
    letter-spacing: -0.02em;
    text-align: center;
    color: var(--secondary);
  }
  .our-team .team-prof .post-title img {
    width: 30px;
    margin-left: 15px;
  }
  .our-team .team-prof .post {
    font-family: Lato;
    font-size: 19px;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: center;
    color: #9fd68b;
  }
  .our-team .team-prof .post img {
    width: 25px;
    margin-right: 15px;
  }
  @media only screen and (max-width: 990px) {
    .our-team {
      width: 100%;
      float: left;
      margin-top: 0;
    }
  }
  /* ********* FAQ-Area CSS************** */

  .faq-area {
    width: 100%;
    float: left;
    padding: 40px 0;
  }

  .faq-inner {
    width: 100%;
    float: left;
  }

  .faq-inner p {
    /* font-family: Roboto; */
    /* font-size: 30px; */
    /* font-weight: 500; */
    /* line-height: 58px; */
    letter-spacing: 0em;
    /* text-align: center; */
    /* padding: 50px 0; */
  }

  .faq-img {
    width: 100%;
    float: left;
  }

  .faq-img img {
    width: 80%;
    float: right;
  }
  .accordion {
    cursor: pointer;
    padding: 15px 70px 15px 0;
    width: 100%;
    border-bottom: 1px solid #bbbbbb;
    outline: none;
    font-family: Roboto;
    font-size: 19px;
    font-weight: 500;
    line-height: 31px;
    letter-spacing: 1px;
    text-align: left;
  }

  .accordion:after {
    content: "\02795"; /* Unicode character for "plus" sign (+) */
    font-size: 14px;
    color: #777;
    float: right;
    margin-right: -70px;
    margin-top: -9px;
  }

  .accordion.active:after {
    content: "\2796"; /* Unicode character for "minus" sign (-) */
  }

  .panel {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.2s ease-out;
  }

  .panel p {
    font-family: "Roboto";
    font-size: 16px;
    font-weight: 400;
    line-height: 32px;
    letter-spacing: 0px;
    text-align: left;
    padding: 20px 0;
    border-bottom: 1px solid #bbbbbb;
    margin: 0;
  }

  .panel ul {
    margin: 40px 0;
  }

  .panel ul > li {
    font-size: 1.4rem;
  }

  /* ********* Testimonial-Area CSS************** */

  .testimonials {
    width: 100%;
    float: left;
    background-image: url(../../../public/assets/img/bgsec1.jpg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    padding: 90px 0;
  }
  .testi {
    text-align: center !important;
  }

  .testi img {
    width: 3% !important;
    margin-top: 20px !important;
    float: left !important;
    position: absolute;
    margin-left: 22px;
  }
  .shadow-effect {
    background: #fff;
    padding: 30px;
    border-radius: 15px;
    text-align: center;
    border: 1px solid #ececec;
    box-shadow: 0 19px 38px rgba(0, 0, 0, 0.1), 0 15px 12px rgba(0, 0, 0, 0.02);
  }
  #customers-testimonials .shadow-effect p {
    font-family: "Lato";
    font-size: 19px;
    line-height: 28px;
    margin: 0 0 17px 0;
    font-weight: 500;
  }
  .testimonial-name {
    margin: -17px auto 0;
    display: table;
    width: auto;
    background: #3190e7;
    padding: 9px 35px;
    border-radius: 12px;
    text-align: center;
    color: #fff;
    box-shadow: 0 9px 18px rgba(0, 0, 0, 0.12), 0 5px 7px rgba(0, 0, 0, 0.05);
  }
  #customers-testimonials .item {
    text-align: center;
    padding: 0;
    margin-bottom: 80px;
    opacity: 1;
    -webkit-transform: scale3d(0.8, 0.8, 1);
    transform: scale3d(0.8, 0.8, 1);
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
  }
  #customers-testimonials .owl-item.active.center .item {
    opacity: 1;
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
  .owl-carousel .owl-item img {
    transform-style: preserve-3d;
    max-width: 90px;
    margin-bottom: 30px;
  }
  #customers-testimonials.owl-carousel .owl-dots .owl-dot.active span,
  #customers-testimonials.owl-carousel .owl-dots .owl-dot:hover span {
    background: #fff;
    transform: translate3d(0px, -50%, 0px) scale(0.7);
  }
  #customers-testimonials.owl-carousel .owl-dots {
    display: inline-block;
    width: 100%;
    text-align: center;
  }
  #customers-testimonials.owl-carousel .owl-dots .owl-dot {
    display: inline-block;
  }
  #customers-testimonials.owl-carousel .owl-dots .owl-dot span {
    background: #fff;
    display: inline-block;
    height: 20px;
    margin: 0 2px 5px;
    transform: translate3d(0px, -50%, 0px) scale(0.3);
    transform-origin: 50% 50% 0;
    transition: all 250ms ease-out 0s;
    width: 20px;
  }
  .owl-nav {
    text-align: center;
  }

  button.owl-prev {
    font-size: 105px !important;
    position: absolute;
    left: 45%;
    bottom: -7.5%;
    color: var(--secondary) !important;
  }

  button.owl-next {
    font-size: 105px !important;
    position: absolute;
    right: 45%;
    bottom: -7.9%;
    color: var(--secondary) !important;
  }

  button.owl-dot.active span {
    color: var(--clrblue) !important;
    border-radius: 24px !important;
  }

  button.owl-dot span {
    color: var(--secondary) !important;
    border-radius: 24px !important;
  }
  .shadow-effect h3 {
    font-family: Lato;
    font-size: 31px;
    font-weight: 700;
    line-height: 33px;
    letter-spacing: 0em;
    text-align: left;
  }

  .shadow-effect h6 {
    font-family: Lato;
    font-size: 16px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0em;
    text-align: left;
  }
  .shadow-effect ul {
    width: 100%;
    float: left;
  }

  .shadow-effect ul li {
    list-style: none;
    display: inline-block;
    margin-top: 34px;
  }

  .shadow-effect ul li img {
    width: 24px !important;
  }
  .shadow-effect h5 {
    font-family: Lato;
    font-size: 27px;
    font-weight: 700;
    line-height: 38px;
    letter-spacing: 0em;
    text-align: center;
    margin-bottom: 14px;
  }

  /* ********* Footer-Area CSS************** */

  .footer-area {
    width: 100%;
    float: left;
    background-color: var(--ftbg);
    padding: 60px 0;
  }

  .footer-inner {
    width: 100%;
    float: left;
  }

  .foot-1 {
    width: 100%;
    float: left;
  }

  .foot-1 img {
    width: 100%;
    float: left;
    margin-bottom: 40px;
  }

  .foot-about {
    width: 100%;
    float: left;
  }

  .foot-about p {
    font-family: "Roboto";
    font-size: 16px;
    font-weight: 600;
    line-height: 21px;
    letter-spacing: 0px;
    text-align: left;
  }

  .foot-about ul {
    width: 100%;
    float: left;
  }

  .foot-about ul li {
    display: block;
    margin-bottom: 8px;
  }

  .foot-about ul li img {
  }

  .foot-2 {
    width: 100%;
    float: left;
  }

  ul.dis-dif {
    width: 100%;
    float: left;
  }

  ul.dis-dif li {
    display: inline-block;
  }

  ul.dis-dif img {
    width: 33px;
    margin-bottom: 0;
    padding: 7px;
  }
  .foot-about ul li a {
    font-family: "Roboto";
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0px;
    text-align: left;
    color: #7d7d7d;
  }
  .foot-3 {
    width: 100%;
    float: left;
  }

  .foot-3 img {
    width: 100%;
    height: 75px;
  }
  input[type="email"] {
    display: inline-block;
    width: 99%;
    padding: 0px 17px;
    margin: 8px 0;
    border: 1px solid #ccc;
    box-sizing: border-box;
    height: 43px;
    margin-top: 18px;
    border-radius: 20px;
    border: none;
    box-shadow: 0 20px 30px 0 rgba(0, 0, 0, 0.06);
    font-family: "Lato";
    font-size: 13px;
  }
  input,
  input::-webkit-input-placeholder {
    font-size: 16px;
    line-height: 3;
  }

  .ft-sb-bt {
    margin-left: -94px;
    font-size: 17px;
    display: inline-block;
    font-weight: 600;
    padding: 10px 10px;
    border-radius: 0px 20px 20px 0px;
    border: none;
    cursor: pointer;
    background-color: var(--clrblue);
    color: #fff;
    font-family: "Lato";
  }

  /* ********* Copyright-Area CSS************** */

  .copy-right-area {
    width: 100%;
    float: left;
    padding: 20px 0 0;
  }

  .copy-right-area p {
    font-family: "Roboto";
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: 0px;
    text-align: center;
    color: #541cf5;
  }

  /* -----------------------
          RESPONSIVE
   ---------------------- */

  /* For Mobile Phones View */

  @media screen and (max-device-width: 767px) {
    .mobile-display-none {
      display: none;
    }
    .mobile-display {
      display: block;
    }
    span.diff-clr-m {
      color: #ef4e4e;
    }
    /* ********* Nav-Area CSS************** */
    a.navbar-brand img {
      width: 150px;
    }
    ul.navbar-nav.me-auto.my-2.my-lg-0.navbar-nav-scroll {
      overflow: initial;
      background-color: var(--secondary);
      z-index: 9999;
      display: block;
      max-height: 50vh;
      padding: 0 16px;
    }
    .search {
      width: 35px;
      height: 33px;
      transform: 0.5s;
      background: #fff;
      overflow: hidden;
      position: relative;
      border-radius: 60px;
      box-shadow: 0 0 0 0 5px #7532d4;
      border: 1px solid var(--clrblue);
      left: 66px;
    }

    .search.active {
      width: 190px;
      border: 1px solid var(--clrblue);
      left: 0;
    }

    .search .icon {
      top: 0;
      left: 1px;
      width: 35px;
      height: 35px;
      display: flex;
      z-index: 1000;
      cursor: pointer;
      position: absolute;
      background: #fff;
      border-radius: 60px;
      align-items: center;
      justify-content: center;
    }

    .search .icon:before {
      content: "";
      width: 15px;
      height: 15px;
      position: absolute;
      border-radius: 50%;
      border: 3px solid #8338ec;
      transform: translate(-4px, -4px);
    }

    .search .icon:after {
      content: "";
      width: 2px;
      height: 8px;
      position: absolute;
      background: #8338ec;
      transform: translate(4px, 5px) rotate(315deg);
    }

    .search .input {
      left: 9px;
      width: 353px;
      height: 33px;
      display: flex;
      position: relative;
      align-items: center;
      justify-content: center;
      font-family: "Roboto";
    }

    .search .input input {
      top: 0;
      width: 85%;
      height: 95%;
      border: none;
      outline: none;
      font-size: 13px;
      padding: 10px 0;
      position: absolute;
    }

    .clear {
      top: 20%;
      right: 15px;
      width: 15px;
      height: 15px;
      display: flex;
      cursor: pointer;
      background: #ff0;
      position: relative;
      align-items: center;
      justify-content: center;
      transform: translateY(-50%);
    }

    .clear:before {
      width: 1px;
      height: 15px;
      content: "";
      position: absolute;
      background: #999;
      transform: rotate(45deg);
    }

    .clear:after {
      width: 1px;
      height: 15px;
      content: "";
      position: absolute;
      background: #999;
      transform: rotate(315deg);
    }

    button.navbar-toggler.collapsed {
      border: none;
      padding: 0;
      color: var(--primary);
    }

    button.navbar-toggler {
      border: none;
      padding: 0;
      color: var(--primary);
    }

    /* ul.navbar-nav.me-auto.my-2.my-lg-0.navbar-nav-scroll {
            overflow: initial;
            background-color: var(--secondary);
            z-index: 9999;
            display: block;
            max-height: 30vh;
            padding: 0 16px;
        }
         */
    li.nav-item {
      border-bottom: 1px solid #efefef;
    }
    /* ********* Hero-Area CSS ************** */
    .hero-text {
      width: 100%;
      float: left;
      padding-right: 0;
      padding-top: 30px;
    }
    .hero-area {
      padding: 40px 0;
      height: 1050px;
    }
    .hero-text h1 {
      font-size: 22px;
      line-height: 0;
    }
    .hero-text h4 {
      font-size: 16px;
      line-height: 50px;
      text-align: center;
      margin-bottom: 0;
    }
    .Subscribe-btn button.btn {
      padding: 6px 25px;
      margin: 0 auto;
      display: block;
      // background-image: linear-gradient(#ff5757, #de4545);
    }
    .Subscribe-btn button.btn a {
      font-size: 13px;
    }
    .btm-text {
      font-size: 12px;
      line-height: 54px;
      text-align: center;
    }
    .img1 {
      width: 78%;
      margin-left: 0;
    }
    .img1 img {
      width: 85%;
      margin-top: 20px;
    }
    .img2 {
      width: 61%;
      position: absolute;
      right: 3.9%;
    }
    .img3 img {
      margin-top: 10px;
      margin-left: 28px;
    }
    .img5 img {
      margin-top: 2px;
    }
    .img4 img {
      margin-left: 13px;
    }
    .hero-img {
      margin-bottom: 40px;
    }
    .icon-bg {
      width: 19%;
      padding: 12px;
      margin: 0 auto;
    }
    .icon-area h5 {
      font-size: 16px;
      line-height: 21px;
      text-align: center;
      margin-top: 20px;
    }
    .icon-area p {
      font-size: 12px;
      line-height: 16px;
      text-align: center;
      padding: 0 77px;
    }
    /* ********* Personal-Data-Area CSS************** */
    .personal-data-area {
      margin-top: 0;
    }
    .personal-data-inner {
      padding: 10px 0px 10px 0px;
      box-shadow: none;
    }
    .personal-data-text h3 {
      font-size: 23px;
      line-height: 27px;
      text-align: center;
      margin-top: 20px;
    }
    .personal-data-img ul li {
      display: block;
    }
    .personal-data-img ul li img {
      width: 29.33%;
      float: left;
      box-shadow: 1px 1px 8px 2px #ddd;
      margin: 0 2%;
      height: 82px;
    }
    .personal-data-img {
      margin-top: 25px;
    }
    /* ********* Pick-Plan-Area CSS************** */
    .pick-plan-area {
      margin: 40px 0;
    }
    h2.heading {
      font-size: 30px;
      line-height: 40px;
    }
    .plan ul li h5 {
      font-size: 15px;
    }
    .plan ul li p {
      font-size: 13px;
      line-height: 19px;
    }
    .plan-btn button.btn {
      padding: 5px 35px;
      margin: 40px auto 0;
    }
    .plan-btn button.btn a {
      font-size: 14px;
      line-height: 8px;
    }
    .plan-bg {
      height: 600px;
      margin: 20px 0;
    }
    .plan-bg1 {
      margin: 0px 0px;
      height: 675px;
    }
    .plan1 h3 {
      font-size: 22px;
      line-height: 30px;
      letter-spacing: 1px;
      // margin-top: 57px;
    }

    .plan1 h6 {
      font-size: 10px;
      line-height: 10px;
      margin-bottom: 17px;
    }
    .seller-area {
      width: 37%;
      margin-top: -196px;
      margin-right: 0;
      float: left;
    }
    .seller-area p {
      font-size: 12px;
    }
    .plan1-btn button.btn {
      /* padding: 4px 10px; */
      margin: 17px auto;
    }
    .plan1-btn button.btn a {
      font-size: 15px;
      line-height: 7px;
    }
    .plan2-btn button.btn {
      /* padding: 4px 19px; */
      margin: 17px auto;
    }
    .plan2-btn button.btn a {
      font-size: 15px;
      line-height: 7px;
    }
    .plan1-para {
      margin-top: -52px;
    }
    .plan1-para p {
      font-size: 13px;
      line-height: 16px;
    }
    .plan1 ul li h5 {
      font-size: 15px;
    }
    .plan1 ul li p {
      font-size: 13px;
      line-height: 19px;
    }
    .m-t {
      height: 760px;
    }
    .m-t-n {
      margin-top: -187px !important;
    }
    /* ********* Make-Us-Area CSS************** */
    .make-us-area {
      width: 100%;
      float: left;
      margin-bottom: 40px;
    }

    .make-us-inner {
      width: 100%;
      float: left;
    }

    .make-us-inner img {
      width: 100%;
      float: left;
    }

    /* ********* Compare-Plan-Area CSS************** */

    .compare-plan-area {
      padding: 20px 0;
    }
    table {
      width: 100%;
      display: block;
      overflow-x: auto;
    }
    th.md {
      font-size: 15px;
      padding: 0px 0px 0 20px;
      line-height: 28px;
    }
    th.care {
      font-size: 14px;
      padding: 0px 20px;
    }
    th.pre {
      padding: 0px 20px;
    }

    th.pre img {
      width: 100%;
    }
    th.pre h3 {
      font-size: 10px;
      margin-top: -16px;
    }
    th.plat {
      padding: 0px 20px;
    }
    th.plat img {
      width: 100%;
    }
    th.plat h3 {
      font-size: 10px;
      margin-top: -16px;
    }
    td.bg-custom {
      padding: 3px 20px;
    }
    .m-table-size {
      font-size: 11px;
      line-height: 18px;
    }
    .m-para-size {
      font-size: 9px;
      line-height: 12px;
      padding: 0;
    }
    .icon-img {
      width: 20%;
    }
    td.pr-tbl span {
      font-size: 13px;
      margin-bottom: 6px;
      white-space: nowrap;
    }
    td.pr-tbl .tbl-btn {
      padding: 0px 7px 4px !important;
    }

    td.pr-tbl .tbl-btn a {
      font-size: 10px !important;
      line-height: 0 !important;
    }

    td.pr-tbl .learn-btn {
      padding: 0 !important;
    }

    td.pr-tbl .learn-btn a {
      font-size: 10px !important;
      line-height: 0 !important;
    }
    /* ********* trust-area CSS************** */
    .trust-area {
      padding: 40px 0;
    }
    h2.heading1 {
      font-size: 26px;
      line-height: normal;
      margin-bottom: 14px;
    }
    .trust-inner {
      margin-top: 20px;
    }
    .trust1 img {
      width: 15%;
    }
    .trust1 h3 {
      font-size: 32px;
      line-height: 57px;
    }
    .trust1 p {
      font-size: 16px;
      line-height: 21px;
      margin-bottom: 50px;
    }
    .trust2 img {
      width: 15%;
    }
    .trust2 h3 {
      font-size: 32px;
      line-height: 57px;
    }
    .trust2 p {
      font-size: 16px;
      line-height: 0px;
      margin-bottom: 50px;
    }

    /* ********* Reason-Area CSS************** */
    .reason-area {
      width: 100%;
      float: left;
      background-image: url(../../../public/assets/img/bgsec4.jpg);
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      margin-top: 0;
      padding: 40px 0;
    }
    .reason-inner {
      width: 100%;
      float: left;
      margin-top: 0;
      padding: 0;
      background: none;
    }
    .reason-inner h4 {
      font-size: 20px;
      line-height: 32px;
      text-align: center;
      margin-top: 0;
    }
    .reason-btn {
      padding: 3px 20px !important;
      margin-top: 0px !important;
      background-color: var(--clrorange) !important;
    }
    /* ********* Partner-Area CSS************** */

    .partner-area {
      margin: 40px 0;
    }
    .text-lf {
      text-align: center !important;
    }
    /* ********* Client-Area CSS************** */

    .client-area {
      padding: 40px 0;
    }
    .client-up {
      margin-bottom: 10px;
      margin-top: 0;
    }
    .m-client {
      width: 25%;
      float: left;
    }
    .client-down {
      width: 100%;
      float: left;
      margin-bottom: 0;
    }
    /* ********* Best-Area CSS************** */

    .best-area {
      padding: 40px 0;
    }
    .best-inner img {
      width: 30%;
      margin: 15px auto 20px;
    }
    .best-inner ul li {
      font-size: 12px;
      line-height: 21px;
      margin-bottom: 10px;
    }
    .best-inner ul li img {
      width: 6%;
      margin: 4px 14px 0 0;
    }
    .center-btn {
      padding: 2px 25px;
      margin: 0 auto !important;
    }

    /* ********* Review-Area CSS************** */

    .review-area {
      padding: 40px 0;
    }
    img.google-img {
      width: 50%;
      display: block;
      margin: 0 auto;
      float: none;
      padding-bottom: 20px;
    }
    img.more-img {
      width: 75%;
      float: none;
      display: block;
      margin: 0 auto;
      padding-bottom: 20px;
    }
    h2.heading.text-lf.cl-wh img {
      width: 10%;
      margin-top: 5px;
      margin-right: -9px;
    }
    img.rat-img {
      margin-top: 15px;
    }

    /* ********* Team-Area CSS************** */

    .team-area {
      padding: 40px 0;
    }
    h2.heading.m-dis {
      font-size: 24px;
      line-height: 30px;
    }
    .our-team .team-prof .post-title {
      font-size: 25px;
    }
    .our-team .team-prof .post-title img {
      width: 20px;
      margin-left: 15px;
    }
    .our-team .team-prof .post {
      font-size: 14px;
    }
    .our-team .team-prof .post img {
      width: 16px;
      margin-right: 9px;
    }
    .our-team .team-content .post-title {
      font-size: 20px;
    }
    .our-team .description {
      font-size: 19px;
      line-height: 30px;
    }
    /* ********* FAQ-Area CSS************** */

    .faq-area {
      padding: 40px 0;
    }
    .faq-inner p {
      font-size: 14px;
      line-height: 11px;
      /* margin: 20px 0; */
    }
    .faq-area .plan1-btn button.btn a {
      font-size: 16px !important;
      line-height: 0px;
    }

    .faq-area .plan1-btn button.btn {
      padding: 10px 40px 5px !important;
    }
    .faq-img {
      margin: 40px 0;
    }
    .faq-img img {
      width: 90%;
      float: none;
      margin: 0 auto;
      display: block;
    }
    .accordion {
      padding: 15px 23px 15px 0;
      font-size: 12px;
      line-height: 18px;
      letter-spacing: 0px;
    }
    .accordion:after {
      font-size: 10px;
      margin-right: -22px;
      margin-top: -4px;
    }
    .panel p {
      font-size: 11px !important;
      line-height: 20px;
      margin: 0;
    }

    /* ********* Testimonial-Area CSS************** */

    .testimonials {
      padding: 40px 0;
    }
    .testi img {
      width: 8% !important;
      margin-top: 9px !important;
      margin-left: 5px;
    }
    #customers-testimonials .item {
      margin-top: -12px;
      margin-bottom: 0px;
    }
    .shadow-effect {
      padding: 30px 15px;
    }
    .shadow-effect h3 {
      font-size: 26px;
      line-height: 19px;
    }
    .shadow-effect h6 {
      font-size: 10px;
      line-height: 16px;
    }
    .shadow-effect ul li img {
      width: 15px !important;
      margin-bottom: 0 !important;
    }
    .shadow-effect ul li {
      margin-top: 23px;
    }
    .shadow-effect img {
      margin-bottom: 0;
    }
    .shadow-effect h5 {
      font-size: 19px;
    }
    #customers-testimonials .shadow-effect p {
      font-size: 14px;
      line-height: 20px;
    }
    /* .owl-nav {
    margin-top: -131px;
} */
    button.owl-prev {
      left: 15%;
      bottom: -9.5%;
    }

    button.owl-next {
      right: 15%;
      bottom: -9.9%;
    }
    /* ********* Footer-Area CSS************** */

    .footer-area {
      padding: 40px 20px;
    }
    .foot-1 img {
      margin-bottom: 20px;
    }
    .foot-about p {
      margin-bottom: 5px;
      font-size: 14px;
    }
    img.m-img {
      width: 60%;
    }
    .foot-about ul li {
      margin-bottom: 3px;
    }
    .foot-about ul {
      margin-bottom: 12px;
    }
    input[type="email"] {
      width: 98%;
      height: 38px;
      margin-top: 7px;
      margin-bottom: 20px;
    }
    .ft-sb-bt {
      margin-left: -86px;
      font-size: 13px;
      padding: 10px 14px;
    }
    .foot-3 img {
      height: 60px;
      margin-top: 14px;
    }

    /* -----Whatsapp-sticky------- */
    .whats-app img {
      text-align: right;
      float: right;
      margin-top: -30px;
      width: 24%;
    }
  }
}
