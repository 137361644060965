.ClientDetails--TableRow {
  position: relative;
  height: 68.8px;
  &:first-child {
    border-top-left-radius: 10px;
  }
  .Table--Name {
    span {
      display: flex;
      align-items: center;
      gap: 5px;
    }

    color: #000;
    font-size: 14px;
    font-family: Inter;
    font-style: normal;
    font-weight: 400;
    line-height: 145.023%;
    letter-spacing: 0.56px;
  }
  .Table--Name,
  .Table--Sessions,
  .Table--Worksheets,
  .Table--SessionNotes {
    p {
      color: #000;
      font-size: 16px;
      font-family: Inter;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }
  }

  .Table--Worksheets,
  .Table--SessionNotes {
   
    span {
      color: #1b8bcf;
      font-size: 14px;
      font-family: Inter;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }

  .Table--SessionNotes {
    p {
      font-size: 16px;
      font-weight: 600;
    }
  }

  .Table--Screener {
    svg {
      width: 20px;
      height: 20px;
      transform: rotate(45deg);
    }
  }
  .Table--Actions {
    width: max-content;
    margin-left: auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 5px;

    button {
      background: #1b8bcf1a;
      padding: 8px 15px;
      height: 36px;
      display: flex;
      align-items: center;
      gap: 5px;
      border-radius: 20px;
      color: #414141;
      font-size: 14px;
      flex: 1;
      max-width: max-content;
      svg {
        width: 16px;
        height: 16px;
      }
      p {
        line-height: 130%;
        text-transform: capitalize;
      }
      &:last-child {
        padding: 8px;
        min-width: 36px;
        width: 36px;
        max-width: 36px;
        aspect-ratio: 1/1;
        flex: 0.25;

        svg {
          width: 20px;
          height: 20px;
        }
      }
    }
  }

  tbody {
    tr {
      td {
        &:last-child {
          display: flex;
          width: max-content;
          margin-left: auto;
        }
      }
    }
  }
}
.SelectedTableRow {
  &::after {
    position: absolute;
    top: 0;
    left: 0;
    content: "";
    height: 100%;
    width: 62px;
    background: linear-gradient(
      90deg,
      rgba(210, 41, 61, 0.1) 0%,
      rgba(210, 41, 61, 0) 100%
    );
    border-left: 2px solid #d2293d;
  }
}

.SessionNoteItem {
  background-color: #f5f5f5;
  justify-content: space-between;
  border-radius: 10px;
  h6 {
    color: #000;
    font-family: Inter;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-bottom: 0 !important;
  }
  & > span {
    color: #656565;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 145.023%; /* 20.303px */
    letter-spacing: 0.21px;
  }
  .Actions {
    div {
      display: flex;
      align-items: center;
      gap: 5px;
      border-radius: 32px;
      background: rgba(27, 139, 207, 0.1);
      padding: 7px 13px;
      font-size: 14px;
      cursor: pointer;
      &:hover {
        background: rgba(27, 139, 207, 0.25);
      }
    }
  }
}
